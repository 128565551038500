import { useState, useEffect } from "react";
import styles from "./commitmentSectionStyles.module.css";
import resortImg from "../../../imgs/commitmentSection/RESORTS.jpeg";
import individualImg from "../../../imgs/commitmentSection/INDIVIDUAL.jpeg";
import LowerCarousel from "../carousels/lowerCarousel/lowerCarousel";


const CommitmentSection = () => {
    const [isModalVisable, setIsModalVisable] = useState(false)
    const [title, setTitle] = useState("resorts");
    const [desc, setDesc] = useState("");
    const [scrollText, setScrollText] = useState("");
    const [selection, setSelection] = useState("resort");

    useEffect(() => {

        if (selection === "lifestyle") {
            setTitle("INDIVIDUAL LIFESTYLES.")
            setDesc(`
            Yurts of America is the answer to affordable yet comfortable green living. Our yurts work as a permanent home, portable camping structure, playhouse for the kids, lake getaway, or any other use you can imagine. You can use it as a guest room, outdoor studio or even spa enclosure!
            
            Our yurts give you the stability of a vacation cabin but with much less cost, and the flexibility of a tent but with much stronger build.
            A yurt offers endless customization options.`)
            setScrollText(`You can install fireplaces, kitchens, bathrooms, even a loft or outdoor deck. Our yurts set up in a matter of hours, and can meet whatever need you send their way.`)
        }
        else {
            setTitle("RESORTS & CAMPGROUNDS.")
            setDesc(`
            Yurts of America offers something that you simply won’t get with other yurt companies, traditional cabins or park models. Yurts of America yurts give a distinct and memorable luxury accommodation that will keep campers coming back!
            
            Today’s glampers and Campers are looking for more than what a traditional campground can offer. Yurts of America offers a wide array of unique sizes, designs and custom options for your needs. Being the last “old school custom yurt companies” If you can dream it up, Yurts of America can help you make that dream come true.replica hublot watch rolex submariner replica watch
            
            How about, Hot tubbing at a bed and breakfast? No problem. Commercial campground rentals? We can help, from design to working with local code enforcement. Yurts of America is there for you, from start to finish. We are not here just to sell you a yurt, but to help see your project though to the end.chinatown replica watches replica rolex watches for women
            
            Traditional cabins can take weeks to build, Yurts of America yurts can be built in a few days. This lets you rent the yurts sooner and provides quicker income to your campground. All yurts of America are “custom” designs, we can easily change the design, material, color, size and floor plans, to fit your campground specific needs.`)
            setScrollText(`Yurts of America Yurts are design to go where traditional cabin, “park models”, tiny homes and other cabin designs, can’t go. IF you can get there so can your yurt. Yurts of America Yurts gives you; set up in relatively remote areas, economical and efficient, which is a big draw for many clients.
            Due to the high quality of material, highest in the industry, our yurts can go up, stay up and give years of useful service. This means low maintenance cost and higher income for the campground.`)
        }

    }, [isModalVisable, selection])

    return (
        <div className={styles.container}>
            <div className={styles.separater} />
            <div className={styles.commitmentHeader}>
                OUR COMMITMENT TO QUALITY AND CRAFTSMANSHIP MAKES IT EASY TO STAND <br /> BEHIND OUR YURTS.
            </div>
            <br />
            <div className={styles.commitmentBody}>
                Based on the traditional Mongolian herders’ hut (a ger), our luxury yurt takes all the ingenuity of those nomadic structures and adds engineered strength, durable outdoor fabrics and handcrafted quality. <br />  Yurts can be set up either as a permanent structure or a portable tent. The structural integrity of a yurt is based on compression and tension working together to form a freestanding, clear span structure, so it can handle nearly anything the elements can throw at it
            </div>
            <div className={styles.lowerCommitment}>
                <div className={styles.imgSection}>
                    <img className={styles.commitmentImg} src={resortImg} alt="resortImg"></img>
                    <div className={styles.imgHeader}> RESORTS <b className={styles.special_amp}> & </b> CAMPGROUNDS </div>
                    <div className={styles.imgBody}> At less than half the cost of a cabin, our yurts are very affordable. The best part? Our premium yurts offer a distinct and memorable luxury accommodation that will keep your campers coming back! </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <button className={styles.button} onClick={() => { setSelection("resorts"); setIsModalVisable(!isModalVisable) }} >Read More</button>
                </div>
                <div className={styles.imgSection}>
                    <img className={styles.commitmentImg} src={individualImg} alt="individualImg"></img>
                    <div className={styles.imgHeader}> INDIVIDUAL LIFESTYLES </div>
                    <div className={styles.imgBody}> Whether you are looking for a permanent yurt or a portable one, Yurts of America offers an affordable green solution. <br /> <br /> Our luxury yurts work as a home, camping structure, playhouse for the kids, lake getaway, or any other use you can imagine. Yurts can also be a guest room, outdoor studio or even a spa enclosure! </div>
                    <button className={styles.button} onClick={() => { setSelection("lifestyle"); setIsModalVisable(!isModalVisable) }}>Read More</button>
                </div>
            </div>
            {isModalVisable &&
                <div className={styles.overlay} onClick={() => setIsModalVisable(!isModalVisable)}>
                    <div className={styles.infoCard}>
                        <div className={styles.cardTitle}>{title}</div>
                        <br />
                        <div className={styles.cardDesc}>{desc}</div>
                        <br />
                        <br />
                        <div>{scrollText}</div>
                    </div>
                </div>}

            <LowerCarousel />

            <div className={styles.videoText}>
                SEE OUR VIDEO TOUR BELOW!
            </div>
            <div className={styles.lowerSeparater} />
        </div>
    )

}


export default CommitmentSection