import styles from "./homePageStyles.module.css"
import ScrollToTop from "../extensions/scrollToTop";
import HomeCarousel from "./carousels/homeCarousel/homeCarousel";
import AboutUsSection from "./aboutUs/aboutUsSection";
import CommitmentSection from "./commitmentSection/commitmentSection";
import YurtWorkSection from "./YurtWorks/yurtWorksSection";
import YoutubeEmbed from "../youtubeEmbed/youtubeEmbed";
// import QuestionsForm from "./questionsForm/questionsForm";
import OurGallery from "./gallerySection/gallerySection";
import TestimonialCarousel from "./carousels/testimonialCarousel/testimonialCarousel";




const HomePage = () => {
    window.onbeforeunload = function () {
        window.history.replaceState({}, document.title)
        ScrollToTop()
    };


    const HomeParallaxOne = () => {
        return (
            <div className={styles.parallaxOne}>
                <div className={styles.content}>
                    <AboutUsSection />
                </div>
            </div>
        );
    }

    const HomeParallaxTwo = () => {

        return (
            <div className={styles.parallaxThree}>
                <div className={styles.youtubeVideo}>
                    <YoutubeEmbed embedId="wDlGQenf6Xs" />
                </div>
            </div>
        );
    }

    // const HomeParallaxThree = () => {

    //     return (
    //         <div className={styles.parallaxTwo}>
    //             <div className={styles.content}>
    //                 <QuestionsForm />
    //             </div>
    //         </div>
    //     );
    // }

    return (
        <div className={styles.container}>
            <HomeCarousel />
            <HomeParallaxOne />
            <CommitmentSection />
            <HomeParallaxTwo />
            <YurtWorkSection />
            <OurGallery />
            <TestimonialCarousel />
        </div>
    );

}

export default HomePage;