import { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import styles from "./headerStyles.module.css";
import logo from "../../imgs/icons/logo.png";
import { YOARoutes } from "../../Constants/Constants";
import Nav from "../extensions/nav";

export const MenuPane = (nav: any) => {
  const [isOpen, setOpen] = useState(false);

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const closeSideBar = () => {
    setOpen(false);
  };

  const showQuestionsForm = () => {
    const dialog = document.getElementById(
      "ModalBackground"
    ) as HTMLDialogElement;
    dialog.style.display = "flex"
    dialog.showModal();
  };

  var menuStyles = {
    bmBurgerButton: {
      position: "fixed",
      width: "36px",
      height: "30px",
      right: "36px",
      top: "25px",
    },
    bmBurgerBars: {
      background: "#373a47",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
      scale: "2",
      margin: "15px 15px 0px 0px",
    },
    bmCross: {
      background: "#FFF",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },
    bmMenu: {
      background: "#000",
      margin: "0px",
      fontSize: "1.15em",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "3em 3em 1em 1em",
    },
    bmItem: {
      display: "inline-block",
      margin: "0px 0px 4px 0px",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };

  const handleNav = () => {
    closeSideBar();
  };

  return (
    <Menu
      right
      noOverlay
      disableAutoFocus
      isOpen={isOpen}
      onOpen={handleIsOpen}
      onClose={handleIsOpen}
      width={"60%"}
      styles={menuStyles}
    >
      <button
        onClick={() => {
          nav(YOARoutes.home);
          handleNav();
        }}
        className={styles.yoaButton}
      >
        {" "}
        HOME{" "}
      </button>
      <button
        onClick={() => {
          nav(YOARoutes.gallery);
          handleNav();
        }}
        className={styles.yoaButton}
      >
        {" "}
        GALLERY{" "}
      </button>
      <button
        onClick={() => {
          nav(YOARoutes.videos);
          handleNav();
        }}
        className={styles.yoaButton}
      >
        {" "}
        VIDEOS{" "}
      </button>
      <button
        onClick={() => {
          nav(YOARoutes.about);
          handleNav();
        }}
        className={styles.yoaButton}
      >
        {" "}
        ABOUT{" "}
      </button>
      <button
        onClick={() => {
          nav(YOARoutes.faqs);
          handleNav();
        }}
        className={styles.yoaButton}
      >
        {" "}
        FAQ'S{" "}
      </button>
      <button onClick={() => showQuestionsForm()} className={styles.yoaButton}>
        {" "}
        CONTACT US{" "}
      </button>
      <button
        onClick={() => {
          nav(YOARoutes.getAQuote);
          handleNav();
        }}
        className={styles.yoaButton}
      >
        {" "}
        GET A QUOTE
      </button>
    </Menu>
  );
};

const MobileHeader = () => {
  const nav = Nav();

  return (
    <div className={styles.headerStyle}>
      <img onClick={() => nav(YOARoutes.home)} src={logo} alt="error" />
      <div className={styles.hamburgerMenu}>{MenuPane(nav)}</div>
    </div>
  );
};

export default MobileHeader;
