import styles from './testimonialStyles.module.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import testimonials from "./testimonials.json";

const TestimonialCarousel = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        useTransform: false,
        adaptiveHeight: false,
        arrows: true,
        focusOnSelect: true,
        mobileFirst: true
    };

    const Testimonial = (index: number) => {
        const testimonial = testimonials[index];
        return (
            <div className={styles.testimonial}>
                <div className={styles.testimonialText}>{testimonial.text}</div>
                <div className={styles.testimonialTextNameLoc}>{testimonial.name}, {testimonial.location}</div>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <div className={styles.headerStyle}> Testimonials</div>
            <Slider {...settings} className={styles.carousel}>
                {Testimonial(0)}
                {Testimonial(1)}
                {Testimonial(2)}
                {Testimonial(3)}
                {Testimonial(4)}
                {Testimonial(5)}
            </Slider>
        </div>
    );
}

export default TestimonialCarousel;