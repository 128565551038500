import styles from "./aboutPageStyles.module.css";
import specialProjects22 from "../../imgs/gallerySection/specialProjectPhotos/specialProjects22.jpeg";
import insideYurts25 from "../../imgs/gallerySection/insidePhotos/insideYurts25.jpeg";
import outsideYurts32 from "../../imgs/gallerySection/outsidePhotos/outsideYurts32.jpg";
import outsideYurts53 from "../../imgs/gallerySection/outsidePhotos/outsideYurts53.jpeg";
import outsideYurts54 from "../../imgs/gallerySection/outsidePhotos/outsideYurts54.jpeg";

const AboutPage = () => {
    const isMobile = window.matchMedia("only screen and (max-width: 1171px)").matches;

    return (
        <div className={styles.container}>
            <div className={styles.header}> ABOUT.</div>
            <div className={styles.aboutBody}>
                <br />
                For 30 years, Yurts of America has been a locally-owned operation that provides affordable luxury yurts to your exact specifications. <br /> <br />

                Every element is made by hand in our shop to meet your needs and provide reliability and convenience. We’ve put our experience to work designing luxury yurts that combine modern materials with classic elements to provide the perfect outdoor experience. <br /> <br />

                Based on the traditional Mongolian herders’ hut (a ger), yurt camping offers the perfect balance between glamping and primitive camping experiences. A yurt uses centuries-old techniques to remain stable and comfortable no matter what Mother Nature has in store! Once you’ve tried out a Yurts of America structure, you’ll learn why so many people love yurts!
            </div>
            <div className={styles.imageBody}>
                <img className={styles.imgs} alt="0" src={specialProjects22} />
                <img className={styles.imgs} alt="2" src={insideYurts25} />
                <img className={styles.imgs} alt="3" src={outsideYurts32} />
                <img className={styles.imgs} alt="4" src={outsideYurts53} />
            </div>

            <div className={styles.imgAndText}>
                {!isMobile && <img className={styles.textImg} alt="5" src={outsideYurts54} />}
                <div className={styles.imgText}>
                    You’ve come to the right place for the best yurts for sale in the USA! Yurts of America is the last of the handmade custom luxury yurt companies and takes pride in providing yurts for everything from a quiet family retreat to major corporate campgrounds. We’re dedicated to building the highest-quality yurts for any climate or weather.
                </div>
            </div>
        </div>
    )

}

export default AboutPage;