import React, { useState } from "react";
import styles from "./questionStyles.module.css";

const QuestionsForm = () => {
    const [fName, setFName] = useState("");
    const [lName, setLName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const valueChanged = (field: string, event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {

        switch (field) {
            case "fname":
                setFName(event.target.value);
                break
            case "lname":
                setLName(event.target.value);
                break
            case "email":
                setEmail(event.target.value);
                break
            case "message":
                setMessage(event.target.value);
                break
        }
    }

    const closeModal = () => {
        const dialog = document.getElementById(
            "ModalBackground"
        ) as HTMLDialogElement;
        dialog.style.display = "none"
        dialog.close();
    }


    return (
        <dialog className={styles.dialogContainer} id="ModalBackground">
            <button className={styles.closeBTN} onClick={() => closeModal()}>X</button>
            <form id="questionsForm" method="dialog" className={styles.form}>
                <label className={styles.questionsLabel} >HAVE ANY QUESTIONS?</label>
                <input className={styles.inputs} type="text" id="fname" name="fname" placeholder="First Name*" value={fName} onChange={(event) => valueChanged("fname", event)} />
                <input className={styles.inputs} type="text" id="lname" name="lname" placeholder="Last Name*" value={lName} onChange={(event) => valueChanged("lname", event)} />
                <input className={styles.inputs} type="text" id="email" name="email" placeholder="E-Mail*" value={email} onChange={(event) => valueChanged("email", event)} /> <br />
                <textarea className={styles.inputsTextbox} id="message" name="message" placeholder="How can we help?*" value={message} onChange={(event) => valueChanged("message", event)} />
                <input id="formButton" className={styles.inputsButton} type="submit" value="Submit"></input>
            </form>
        </dialog>
    )
}

export default QuestionsForm