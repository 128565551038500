import React, { useState } from "react";
import styles from "./yurtColorStyles.module.css";
import ColorSelection from "../../../extensions/colorSelection/colorSelection";
import { yurtColors } from "../../../../Constants/Constants";
import yurtSections from "../../../../imgs/icons/yurtSections.png";
import YOACheckBox from "../../../extensions/checkbox/YOACheckBox";

const YurtColorSection = (yurt: YurtType, setYurt: React.Dispatch<React.SetStateAction<YurtType>>) => {

    const [isTopUpgraded, setIsTopUpgraded] = useState(false)

    const { yoaCheckbox: duroLastCheckbox, toggleChecked: toggleDuroLastCheckbox } = YOACheckBox("Upgrade to DL50 Terra Cotta Durolast ", () => { setIsTopUpgraded(!isTopUpgraded); setTopColor(yurtColors.bottom.terraCota) });

    const setTopColor = (color: YurtColor) => {
        setYurt({ ...yurt, topColor: color })
    }

    const setBottomColor = (color: YurtColor) => {
        setYurt({ ...yurt, bottomColor: color })
    }

    return (
        <div className={styles.container}>
            <div className={styles.inlineSelection}>
                Top Cover Color selection:
                <div className={styles.colorGroup}>
                    {ColorSelection(isTopUpgraded, yurtColors.top.array, yurt.topColor, setTopColor, () => { toggleDuroLastCheckbox(false); setIsTopUpgraded(false) })}<br /> <br />
                </div>
                <div className={styles.terraCotaUpgrade}>{duroLastCheckbox}</div>

                Polyester Side Cover Color selection:
                <div className={styles.colorGroup}>
                    {ColorSelection(false, yurtColors.bottom.array, yurt.bottomColor, setBottomColor)}<br /> <br />
                </div>
                <img className={styles.diagramImg} src={yurtSections} alt="yurtSections" />
            </div>


        </div>
    )
}

export default YurtColorSection;