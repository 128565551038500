import PropTypes from "prop-types";
import styles from './youtubeEmbedStyles.module.css';

const YoutubeEmbed = ({ embedId }: { embedId: String }) => (
  <div className={styles.videoresponsive}>
    <iframe
      width="auto"
      height="auto"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="High Falls yurt"
    />
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;