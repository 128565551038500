import styles from './yurtStyles.module.css';
import img1 from '../../../imgs/worksImg/img1.png';
import img2 from '../../../imgs/worksImg/img2.png';
import img3 from '../../../imgs/worksImg/img3.png';
import Nav from "../../extensions/nav";
import { YOARoutes } from "../../../Constants/Constants";


const YurtWorkSection = () => {

    const nav = Nav();

    return (
        <div className={styles.backg}>

            <h1>HOW A YURT WORKS?</h1>

            <div>How can a seemingly simple and lightweight yurt handle weather as harsh as the Gobi Desert steppe climate where high winds and bitter cold is the average?</div>
            <div className={styles.imgGroup}>
                <img className={styles.groupImg} src={img1} alt="img1"></img>
                <img className={styles.groupImg} src={img2} alt="img2"></img>
                <img className={styles.groupImg} src={img3} alt="img3s"></img>
            </div>

            <div className={styles.learnMore}>Learn the answer to this and more</div>

            <button className={styles.learnButton} onClick={() => nav(YOARoutes.faqs)}>Learn More</button>

        </div>

    );

}

export default YurtWorkSection;