import React from "react";
import prev from '../../../../imgs/icons/prev.png';

import styles from './arrowStyles.module.css';


export interface Props {
    onClick: React.MouseEventHandler<HTMLDivElement>;
}

const CustomNextArrow = (props: Props) => {
    return (
        <div className={styles.next} onClick={props.onClick}>
            <img alt="next" src={prev}></img>
        </div>
    );
}

export default CustomNextArrow;