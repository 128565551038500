import { Object3D } from "three";

const GetContainer = (childObj: Object3D): any => {
  if (childObj.userData.isContainer === true) return childObj;

  if (childObj.parent != null) return GetContainer(childObj.parent);

  return null;
};

export default GetContainer;
