import { Cache } from "three";
import dome from "../../../imgs/modelFiles/common/dome.glb";
import top from "../../../imgs/modelFiles/common/top.glb";
import body from "../../../imgs/modelFiles/common/body.glb";
import ChangeModelColor from "../changeModelColor";

const LoadCommon = async (
  loader: any,
  scene: THREE.Scene,
  topColor: YurtColor,
  baseColor: YurtColor
) => {
  const modelLoader = (url: string) => {
    return new Promise((resolve, reject) => {
      loader.load(url, (data: any) => resolve(data), undefined, reject);
    });
  };
  const getModel = async (modelName: string) => {
    let cachedModel = undefined;
    let model = undefined;

    cachedModel = Cache.get(modelName);

    if (cachedModel === undefined) {
      model = await modelLoader(modelName);
      Cache.add(modelName, model);
    } else {
      model = cachedModel;
    }

    return model;
  };

  await getModel(dome).then((model) => {
    const domeScene = model.scene;
    domeScene.name = "dome";
    scene.add(domeScene);
  });

  await getModel(top).then((model) => {
    const topScene = model.scene;
    topScene.name = "top";
    scene.add(topScene);
    ChangeModelColor(topScene, topColor);
  });
  await getModel(body).then((model) => {
    const baseScene = model.scene;
    baseScene.name = "base";
    scene.add(baseScene);
    ChangeModelColor(baseScene, baseColor);
  });
};

export default LoadCommon;
