import {
  Scene,
  LoadingManager,
  WebGLRenderer,
  ReinhardToneMapping,
  sRGBEncoding,
  Cache,
  HemisphereLight,
  PerspectiveCamera,
  REVISION,
} from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";

const YurtScene = () => {
  const scene = new Scene();
  const isMobile = window.matchMedia(
    "only screen and (max-width: 1171px)"
  ).matches;
  const MANAGER = new LoadingManager();
  const THREE_PATH = `https://unpkg.com/three@0.${REVISION}.x`;
  const DRACO_LOADER = new DRACOLoader(MANAGER).setDecoderPath(
    `${THREE_PATH}/examples/jsm/libs/draco/gltf/`
  );
  const KTX2_LOADER = new KTX2Loader(MANAGER).setTranscoderPath(
    `${THREE_PATH}/examples/jsm/libs/basis/`
  );

  const renderer = new WebGLRenderer({ antialias: true, alpha: true });
  renderer.setSize(window.innerWidth / 3, window.innerHeight / 3);
  if (isMobile) {
    renderer.setSize(window.innerWidth / 2.5, window.innerHeight / 3);
  }
  renderer.setClearColor(0xffffff, 0);
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.toneMapping = ReinhardToneMapping;
  renderer.toneMappingExposure = 2.5;
  renderer.shadowMap.enabled = true;
  renderer.outputEncoding = sRGBEncoding;
  renderer.setClearColor(0xffffff);
  renderer.setPixelRatio(window.devicePixelRatio);
  Cache.enabled = true;

  const light = new HemisphereLight(0x7a7a7a, 0x1a1a1a, 0.8 * Math.PI); // soft white light
  scene.add(light);

  var camera = new PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );


  if (isMobile) {
    camera = new PerspectiveCamera(
      100,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
  }
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enablePan = false;
  controls.enableZoom = true;
  controls.minDistance = 8;
  controls.maxDistance = 15;
  controls.minPolarAngle = 0;
  controls.maxPolarAngle = Math.PI / 2;

  controls.target.set(0, 0, 0);

  const loader = new GLTFLoader(MANAGER)
    .setCrossOrigin("anonymous")
    .setDRACOLoader(DRACO_LOADER)
    .setKTX2Loader(KTX2_LOADER.detectSupport(renderer));

  camera.position.y = -3;
  camera.position.z = -1;

  function animate() {
    renderer.render(scene, camera);
    controls.update();
    requestAnimationFrame(animate);
  }
  animate();

  return { renderer, scene, loader };
};

export default YurtScene;
