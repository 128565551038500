import './App.css';
import CategorySelection from './features/galleryPage/categorySelection/categorySelection';
import VideoCategorySelection from './features/videoPage/videoCategorySelection';
import { YOARoutes } from "./Constants/Constants";
import { Route, Routes } from "react-router";
import CategoryDisplay from './features/galleryPage/categoryDisplay/categoryDisplay';
import AboutPage from './features/aboutPage/aboutPage';
import FaqsPage from './features/faqs/faqsPage';
import QuotePage from './features/quotePage/quotePage';
import HomePage from './features/home/homePage';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path={YOARoutes.home} element={<HomePage />} />
        <Route path={YOARoutes.gallery} element={<CategorySelection />} />
        <Route path={YOARoutes.videos} element={<VideoCategorySelection />} />
        <Route path={YOARoutes.imgCategory} element={<CategoryDisplay />} />
        <Route path={YOARoutes.about} element={<AboutPage />} />
        <Route path={YOARoutes.faqs} element={<FaqsPage />} />
        <Route path={YOARoutes.getAQuote} element={<QuotePage />} />
      </Routes>
    </div>
  );
}

export default App;
