//outside yurt images
import outsideYurt1 from "../imgs/gallerySection/outsidePhotos/outsideYurts1.jpeg";
import outsideYurt2 from "../imgs/gallerySection/outsidePhotos/outsideYurts2.jpeg";
import outsideYurt3 from "../imgs/gallerySection/outsidePhotos/outsideYurts3.jpeg";
import outsideYurt4 from "../imgs/gallerySection/outsidePhotos/outsideYurts4.jpeg";
import outsideYurt5 from "../imgs/gallerySection/outsidePhotos/outsideYurts5.jpeg";
import outsideYurt6 from "../imgs/gallerySection/outsidePhotos/outsideYurts6.jpeg";
import outsideYurt7 from "../imgs/gallerySection/outsidePhotos/outsideYurts7.jpeg";
import outsideYurt8 from "../imgs/gallerySection/outsidePhotos/outsideYurts8.jpeg";
import outsideYurt9 from "../imgs/gallerySection/outsidePhotos/outsideYurts9.jpeg";
import outsideYurt10 from "../imgs/gallerySection/outsidePhotos/outsideYurts10.jpeg";
import outsideYurt11 from "../imgs/gallerySection/outsidePhotos/outsideYurts11.jpeg";
import outsideYurt12 from "../imgs/gallerySection/outsidePhotos/outsideYurts12.jpeg";
import outsideYurt13 from "../imgs/gallerySection/outsidePhotos/outsideYurts13.jpeg";
import outsideYurt14 from "../imgs/gallerySection/outsidePhotos/outsideYurts14.jpeg";
import outsideYurt15 from "../imgs/gallerySection/outsidePhotos/outsideYurts15.jpeg";
import outsideYurt16 from "../imgs/gallerySection/outsidePhotos/outsideYurts16.jpeg";
import outsideYurt17 from "../imgs/gallerySection/outsidePhotos/outsideYurts17.jpeg";
import outsideYurt18 from "../imgs/gallerySection/outsidePhotos/outsideYurts18.jpeg";
import outsideYurt19 from "../imgs/gallerySection/outsidePhotos/outsideYurts19.jpeg";
import outsideYurt20 from "../imgs/gallerySection/outsidePhotos/outsideYurts20.jpeg";
import outsideYurt21 from "../imgs/gallerySection/outsidePhotos/outsideYurts21.jpeg";
import outsideYurt22 from "../imgs/gallerySection/outsidePhotos/outsideYurts22.jpeg";
import outsideYurt23 from "../imgs/gallerySection/outsidePhotos/outsideYurts23.jpeg";
import outsideYurt24 from "../imgs/gallerySection/outsidePhotos/outsideYurts24.jpeg";
import outsideYurt25 from "../imgs/gallerySection/outsidePhotos/outsideYurts25.jpeg";
import outsideYurt26 from "../imgs/gallerySection/outsidePhotos/outsideYurts26.jpeg";
import outsideYurt27 from "../imgs/gallerySection/outsidePhotos/outsideYurts27.jpeg";
import outsideYurt28 from "../imgs/gallerySection/outsidePhotos/outsideYurts28.jpeg";
import outsideYurt29 from "../imgs/gallerySection/outsidePhotos/outsideYurts29.jpeg";
import outsideYurt30 from "../imgs/gallerySection/outsidePhotos/outsideYurts30.jpeg";
import outsideYurt31 from "../imgs/gallerySection/outsidePhotos/outsideYurts31.jpeg";
import outsideYurt32 from "../imgs/gallerySection/outsidePhotos/outsideYurts32.jpg";
import outsideYurt33 from "../imgs/gallerySection/outsidePhotos/outsideYurts33.jpeg";
import outsideYurt34 from "../imgs/gallerySection/outsidePhotos/outsideYurts34.jpeg";
import outsideYurt35 from "../imgs/gallerySection/outsidePhotos/outsideYurts35.jpeg";
import outsideYurt36 from "../imgs/gallerySection/outsidePhotos/outsideYurts36.jpeg";
import outsideYurt37 from "../imgs/gallerySection/outsidePhotos/outsideYurts37.jpeg";
import outsideYurt39 from "../imgs/gallerySection/outsidePhotos/outsideYurts39.jpeg";
import outsideYurt40 from "../imgs/gallerySection/outsidePhotos/outsideYurts40.jpeg";
import outsideYurt41 from "../imgs/gallerySection/outsidePhotos/outsideYurts41.jpeg";
import outsideYurt42 from "../imgs/gallerySection/outsidePhotos/outsideYurts42.jpeg";
import outsideYurt43 from "../imgs/gallerySection/outsidePhotos/outsideYurts43.jpeg";
import outsideYurt44 from "../imgs/gallerySection/outsidePhotos/outsideYurts44.jpg";
import outsideYurt45 from "../imgs/gallerySection/outsidePhotos/outsideYurts45.jpeg";
import outsideYurt46 from "../imgs/gallerySection/outsidePhotos/outsideYurts46.jpeg";
import outsideYurt47 from "../imgs/gallerySection/outsidePhotos/outsideYurts47.jpeg";
import outsideYurt48 from "../imgs/gallerySection/outsidePhotos/outsideYurts48.jpeg";
import outsideYurt49 from "../imgs/gallerySection/outsidePhotos/outsideYurts49.jpeg";
import outsideYurt50 from "../imgs/gallerySection/outsidePhotos/outsideYurts50.jpeg";
import outsideYurt51 from "../imgs/gallerySection/outsidePhotos/outsideYurts51.jpeg";


//inside photos
import insideYurt1 from "../imgs/gallerySection/insidePhotos/insideYurts1.jpeg";
import insideYurt2 from "../imgs/gallerySection/insidePhotos/insideYurts2.jpeg";
import insideYurt3 from "../imgs/gallerySection/insidePhotos/insideYurts3.jpeg";
import insideYurt4 from "../imgs/gallerySection/insidePhotos/insideYurts4.jpeg";
import insideYurt5 from "../imgs/gallerySection/insidePhotos/insideYurts5.jpeg";
import insideYurt6 from "../imgs/gallerySection/insidePhotos/insideYurts6.jpeg";
import insideYurt7 from "../imgs/gallerySection/insidePhotos/insideYurts7.jpeg";
import insideYurt8 from "../imgs/gallerySection/insidePhotos/insideYurts8.jpeg";
import insideYurt9 from "../imgs/gallerySection/insidePhotos/insideYurts9.jpeg";
import insideYurt10 from "../imgs/gallerySection/insidePhotos/insideYurts10.jpeg";
import insideYurt11 from "../imgs/gallerySection/insidePhotos/insideYurts11.jpeg";
import insideYurt12 from "../imgs/gallerySection/insidePhotos/insideYurts12.jpeg";
import insideYurt13 from "../imgs/gallerySection/insidePhotos/insideYurts13.jpeg";
import insideYurt14 from "../imgs/gallerySection/insidePhotos/insideYurts14.jpeg";
import insideYurt15 from "../imgs/gallerySection/insidePhotos/insideYurts15.jpeg";
import insideYurt16 from "../imgs/gallerySection/insidePhotos/insideYurts16.png";
import insideYurt17 from "../imgs/gallerySection/insidePhotos/insideYurts17.jpeg";
import insideYurt18 from "../imgs/gallerySection/insidePhotos/insideYurts18.jpeg";
import insideYurt19 from "../imgs/gallerySection/insidePhotos/insideYurts19.jpeg";
import insideYurt20 from "../imgs/gallerySection/insidePhotos/insideYurts20.jpeg";
import insideYurt21 from "../imgs/gallerySection/insidePhotos/insideYurts21.jpeg";
import insideYurt22 from "../imgs/gallerySection/insidePhotos/insideYurts22.jpeg";
import insideYurt23 from "../imgs/gallerySection/insidePhotos/insideYurts23.jpeg";
import insideYurt24 from "../imgs/gallerySection/insidePhotos/insideYurts24.jpeg";
import insideYurt25 from "../imgs/gallerySection/insidePhotos/insideYurts25.jpeg";
import insideYurt26 from "../imgs/gallerySection/insidePhotos/insideYurts26.jpeg";
import insideYurt27 from "../imgs/gallerySection/insidePhotos/insideYurts27.jpeg";
import insideYurt28 from "../imgs/gallerySection/insidePhotos/insideYurts28.jpeg";
import insideYurt29 from "../imgs/gallerySection/insidePhotos/insideYurts29.jpeg";
import insideYurt30 from "../imgs/gallerySection/insidePhotos/insideYurts30.jpeg";
import insideYurt31 from "../imgs/gallerySection/insidePhotos/insideYurts31.jpeg";
import insideYurt32 from "../imgs/gallerySection/insidePhotos/insideYurts32.jpeg";


//loft photos
import loftPhoto1 from "../imgs/gallerySection/loftPhotos/loftPhotos1.jpeg";
import loftPhoto2 from "../imgs/gallerySection/loftPhotos/loftPhotos2.jpeg";
import loftPhoto3 from "../imgs/gallerySection/loftPhotos/loftPhotos3.jpeg";
import loftPhoto4 from "../imgs/gallerySection/loftPhotos/loftPhotos4.jpeg";
import loftPhoto5 from "../imgs/gallerySection/loftPhotos/loftPhotos5.jpeg";
import loftPhoto6 from "../imgs/gallerySection/loftPhotos/loftPhotos6.jpeg";
import loftPhoto7 from "../imgs/gallerySection/loftPhotos/loftPhotos7.jpeg";
import loftPhoto8 from "../imgs/gallerySection/loftPhotos/loftPhotos8.jpeg";
import loftPhoto9 from "../imgs/gallerySection/loftPhotos/loftPhotos9.jpeg";


//special Projects
import specialProject1 from "../imgs/gallerySection/specialProjectPhotos/specialProjects1.jpeg";
import specialProject2 from "../imgs/gallerySection/specialProjectPhotos/specialProjects2.jpeg";
import specialProject3 from "../imgs/gallerySection/specialProjectPhotos/specialProjects3.jpeg";
import specialProject4 from "../imgs/gallerySection/specialProjectPhotos/specialProjects4.jpeg";
import specialProject5 from "../imgs/gallerySection/specialProjectPhotos/specialProjects5.jpeg";
import specialProject6 from "../imgs/gallerySection/specialProjectPhotos/specialProjects6.jpeg";
import specialProject7 from "../imgs/gallerySection/specialProjectPhotos/specialProjects7.jpeg";
import specialProject8 from "../imgs/gallerySection/specialProjectPhotos/specialProjects8.jpeg";
import specialProject9 from "../imgs/gallerySection/specialProjectPhotos/specialProjects9.jpeg";
import specialProject10 from "../imgs/gallerySection/specialProjectPhotos/specialProjects10.jpeg";
import specialProject11 from "../imgs/gallerySection/specialProjectPhotos/specialProjects11.jpeg";
import specialProject12 from "../imgs/gallerySection/specialProjectPhotos/specialProjects12.jpeg";
import specialProject13 from "../imgs/gallerySection/specialProjectPhotos/specialProjects13.jpeg";
import specialProject14 from "../imgs/gallerySection/specialProjectPhotos/specialProjects14.jpeg";
import specialProject15 from "../imgs/gallerySection/specialProjectPhotos/specialProjects15.jpeg";
import specialProject16 from "../imgs/gallerySection/specialProjectPhotos/specialProjects16.jpeg";
import specialProject17 from "../imgs/gallerySection/specialProjectPhotos/specialProjects17.jpeg";
import specialProject18 from "../imgs/gallerySection/specialProjectPhotos/specialProjects18.jpeg";
import specialProject19 from "../imgs/gallerySection/specialProjectPhotos/specialProjects19.jpeg";
import specialProject20 from "../imgs/gallerySection/specialProjectPhotos/specialProjects20.jpeg";
import specialProject21 from "../imgs/gallerySection/specialProjectPhotos/specialProjects21.jpeg";
import specialProject22 from "../imgs/gallerySection/specialProjectPhotos/specialProjects22.jpeg";


//options photos
import options1 from "../imgs/gallerySection/optionsPhotos/options1.jpeg";
import options2 from "../imgs/gallerySection/optionsPhotos/options2.jpeg";
import options3 from "../imgs/gallerySection/optionsPhotos/options3.jpeg";
import options4 from "../imgs/gallerySection/optionsPhotos/options4.jpeg";
import options5 from "../imgs/gallerySection/optionsPhotos/options5.jpeg";
import options6 from "../imgs/gallerySection/optionsPhotos/options6.jpeg";
import options7 from "../imgs/gallerySection/optionsPhotos/options7.jpeg";
import options8 from "../imgs/gallerySection/optionsPhotos/options8.jpeg";
import options9 from "../imgs/gallerySection/optionsPhotos/options9.jpeg";
import options10 from "../imgs/gallerySection/optionsPhotos/options10.jpeg";


//under construction photos
import underConstruction1 from "../imgs/gallerySection/underConstructionPhotos/underConstruction1.jpeg";
import underConstruction2 from "../imgs/gallerySection/underConstructionPhotos/underConstruction2.jpeg";
import underConstruction3 from "../imgs/gallerySection/underConstructionPhotos/underConstruction3.jpeg";
import underConstruction4 from "../imgs/gallerySection/underConstructionPhotos/underConstruction4.jpeg";
import underConstruction5 from "../imgs/gallerySection/underConstructionPhotos/underConstruction5.jpeg";
import underConstruction6 from "../imgs/gallerySection/underConstructionPhotos/underConstruction6.jpeg";
import underConstruction7 from "../imgs/gallerySection/underConstructionPhotos/underConstruction7.jpeg";
import underConstruction8 from "../imgs/gallerySection/underConstructionPhotos/underConstruction8.jpeg";
import underConstruction9 from "../imgs/gallerySection/underConstructionPhotos/underConstruction9.jpeg";
import underConstruction10 from "../imgs/gallerySection/underConstructionPhotos/underConstruction10.jpeg";
import underConstruction11 from "../imgs/gallerySection/underConstructionPhotos/underConstruction11.jpeg";
import underConstruction12 from "../imgs/gallerySection/underConstructionPhotos/underConstruction12.jpeg";
import underConstruction13 from "../imgs/gallerySection/underConstructionPhotos/underConstruction13.jpeg";
import underConstruction14 from "../imgs/gallerySection/underConstructionPhotos/underConstruction14.jpeg";
import underConstruction15 from "../imgs/gallerySection/underConstructionPhotos/underConstruction15.jpeg";



export const HomePageText = {
    aboutUsHeader: "Yurts of America is the best kept secret in affordable luxury yurts. ",
    aboutUsText: "As one of the last handmade custom yurt companies in the country, each structure is crafted in our shop by skilled people who take pride in their work. From quiet family retreats to major corporate campgrounds, we’re dedicated to building the highest-quality yurts for any climate or weather. We use the best materials, the finest design and superior engineering to build the best yurt available anywhere."
};

export const YOARoutes = {
    home: "/",
    gallery: "/gallery",
    videos: "/videos",
    about: "/about",
    faqs: "/faqs",
    contactUs: "/contact-us",
    getAQuote: "/get-a-quote",
    imgCategory: "/image-category"

}

export const outsideImgs = [outsideYurt1, outsideYurt2, outsideYurt3, outsideYurt4, outsideYurt5, outsideYurt6, outsideYurt7, outsideYurt8, outsideYurt9, outsideYurt10,
    outsideYurt11, outsideYurt12, outsideYurt13, outsideYurt14, outsideYurt15, outsideYurt16, outsideYurt17, outsideYurt18, outsideYurt19, outsideYurt20,
    outsideYurt21, outsideYurt22, outsideYurt23, outsideYurt24, outsideYurt25, outsideYurt26, outsideYurt27, outsideYurt28, outsideYurt29, outsideYurt30,
    outsideYurt31, outsideYurt32, outsideYurt33, outsideYurt34, outsideYurt35, outsideYurt36, outsideYurt37, outsideYurt39, outsideYurt40,
    outsideYurt41, outsideYurt42, outsideYurt43, outsideYurt44, outsideYurt45, outsideYurt46, outsideYurt47, outsideYurt48, outsideYurt49, outsideYurt50,
    outsideYurt51];

export const insideImgs = [insideYurt1, insideYurt2, insideYurt3, insideYurt4, insideYurt5, insideYurt6, insideYurt7, insideYurt8, insideYurt9, insideYurt10,
    insideYurt11, insideYurt12, insideYurt13, insideYurt14, insideYurt15, insideYurt16, insideYurt17, insideYurt18, insideYurt19, insideYurt20,
    insideYurt21, insideYurt22, insideYurt23, insideYurt24, insideYurt25, insideYurt26, insideYurt27, insideYurt28, insideYurt29, insideYurt30,
    insideYurt31, insideYurt32];

export const loftImgs = [loftPhoto1, loftPhoto2, loftPhoto3, loftPhoto4, loftPhoto5, loftPhoto6, loftPhoto7, loftPhoto8, loftPhoto9];

export const specialProjectImgs = [specialProject1, specialProject2, specialProject3, specialProject4, specialProject5, specialProject6, specialProject7, specialProject8, specialProject9, specialProject10,
    specialProject11, specialProject12, specialProject13, specialProject14, specialProject15, specialProject16, specialProject17, specialProject18, specialProject19, specialProject20,
    specialProject21, specialProject22];

export const optionsImgs = [options1, options2, options3, options4, options5, options6, options7, options8, options9, options10];

export const underConstructionImgs = [underConstruction1, underConstruction2, underConstruction3, underConstruction4, underConstruction5, underConstruction6, underConstruction7, underConstruction8, underConstruction9, underConstruction10,
    underConstruction11, underConstruction12, underConstruction13, underConstruction14, underConstruction15];

export const yurtColors = {
    top: {
        darkGray: { name: "Dark Gray", hex: "#97958C" },
        gray: { name: "Gray", hex: "#D3CFC0" },
        white: { name: "White", hex: "#FFFFFF" },
        tan: { name: "Tan", hex: "#DAA16A" },
        array: [{ name: "Dark Gray", hex: "#97958C" }, { name: "Gray", hex: "#D3CFC0" }, { name: "White", hex: "#FFFFFF" }, { name: "Tan", hex: "#DAA16A" }]
    },
    bottom: {
        brown: { name: "Brow", hex: "#6A4C39" },
        coolGray: { name: "Cool Gray", hex: "#62645C" },
        cranberry: { name: "Cranberry", hex: "#672C27" },
        forestGreen: { name: "Forest Green", hex: "#33543E" },
        darkLinen: { name: "Dark Linen", hex: "#7F765E" },
        navy: { name: "Navy", hex: "#313B3F" },
        olive: { name: "Olive", hex: "#5F6329" },
        teal: { name: "Teal", hex: "#0C7474" },
        terraCota: { name: "Terra Cota", hex: "#BF5122" },
        toast: { name: "Toast", hex: "#8F5C2C" },
        array: [{ name: "Brow", hex: "#6A4C39" }, { name: "Cool Gray", hex: "#62645C" }, { name: "Cranberry", hex: "#672C27" },
        { name: "Forest Green", hex: "#33543E" }, { name: "Dark Linen", hex: "#7F765E" },
        { name: "Navy", hex: "#313B3F" }, { name: "Olive", hex: "#5F6329" }, { name: "Teal", hex: "#0C7474" },
        { name: "Terra Cota", hex: "#BF5122" }, { name: "Toast", hex: "#8F5C2C" }]

    }
}


export const galleryCategories = {
    outside: "outside photos",
    inside: "inside photos",
    loft: "loft",
    special: "special projects",
    options: "options",
    construction: "under construction"
}


export const faqsData = [
    {
        question: `What is a yurt?`, answer:
            `By today’s standards a “commercial” yurt is designated as a “semi-permanent” round structure. This is a fancy way of saying it’s can be built or removed in less than a day with simple tools. Modern “commercial design” yurts can be installed and stay up for years. Commercial yurts come in many sizes from 12’ up to 32’.

    From history; A yurt or ger is a portable living structure originally developed by the nomadic groups of the Central Asian steppes. They used a lattice wall, circular design and radial rafters to maximize structural strength. The original yurts were designed to be easily portable as they drove horse and goat herds across the Asia plains. Strong and light, these magnificent structures could be set up in a day or so and easily packed up to move.`},
    {
        question: `How strong are your yurts?`, answer:
            `Yurts of America makes some of the strongest yurts on the market. Where Yurts of America “standards” specs start, are upgrades for many other companies. Using the highest quality of materials and craftsmanship, our yurts are designed to be the strongest “wood framed” yurts on the market.

    Due to the basic architectural principles, though we have used modern building materials, the structural design has changed very little in over 1500 years. It’s easy to say our yurts can handle a lot.`},
    { question: `Will my yurt really stay up year-round?`, answer: `Absolutely! Our yurts have a highly stable construction that allows them to remain in place for years on end. Our standard covers come with a 10-year warranty, and our upgraded commercial roofing covers are warrantied for 15 years. Our yurts can handle most weather in most areas, and our optional wind and snow kit will help your yurt endure very hardy weather in rough territory.` },
    { question: `How long will my yurt last?`, answer: `A properly installed and maintained yurt will last as long as a house. Just like any structure, proper maintenance is the key. Relatively little maintenance is required.` },
    { question: `What are yurts used for?`, answer: `You can use a yurt for virtually any purpose you can imagine! Our yurts currently serve as bed & breakfasts, bed & breakfasts, permanent homes, temporary homes while building permanent homes, lake homes, rentals at campgrounds, workshops, and, of course, campground cabins. (A fraternity at an un-named Midwest university purchased one for an outside smoking lounge.)` },
    {
        question: `What tools do I need to set up my yurt?`, answer:
            `No special tools are typically needed to set up a yurt. Yurts of America design is such, that few tools are needed, in most cases simple hand tools, as listed. A full list is sent to you before you receive your yurt. This list is ONLY for what is needed to setup our yurt. It does not include anything needed to build the platform your yurt sits on.
    \r\n
    Note, depending on size of yurt and options, some tools might need to be rented if you don’t have them.
    
    Some of the tools are:
    
    - Battery-operated drills with extra batteries
    - Tape measure
    - At least two ladders
    - Utility knife (with sharp blade)
    - Staple gun.

    Something to hold the compression ring off the ground.
    This is not a complete list of tools, but you can see how simple the project is going to be.`},
    { question: `How many people does it take to set up my yurt?`, answer: `Smaller yurts require a minimum of two people, and the 24’ and 30’ require four to five people for safety. More are helpful particularly on the larger yurts. At some stages of the yurt build, more is better, but too many can become a safety problem` },
    { question: `How long will it take me to set up my yurt?`, answer: `Depending on options, smaller yurts can be set up in a day. Larger yurts should have a day budgeted for the frame, and a day budgeted for the cover. A 30’ foot yurt with all of the options will take three days.` },
    { question: `Do you use canvas as covers in your yurts?`, answer: `No. Untreated canvas doesn’t stand up to the sun’s UV rays, and even treated canvas must be retreated with fire retardants and UV inhibitors to have a chance of long-term survival outside. Our vinyl sides have a 10-year warranty and Duro-last tops have a 15-year warranty.` },
    { question: `Is the platform included?`, answer: `We provide the yurt – you provide the platform. Yurts of America does have site plans available for building your platform and installing your yurt.` },
    { question: `Can I heat my yurt? Will utilities run into it?`, answer: `Yes. A licensed professional can help you install water, electric, sewer and/or gas through the platform. Combustion air and flue gas enter and exit through the wall. The optional stove kit is necessary for these uses.` },
    { question: `How many people can I get in my yurt?`, answer: `It depends on what they are doing and how long they will be staying. A 20’ yurt will easily house four bunk beds and lockers for a summer campground. A 30’ yurt with a sleeping loft can have nine hundred square feet of space – enough room for two people to permanently live in relative comfort.` },
    {
        question: `Do you help set up yurts?`, answer:
            `As a rule no, our yurt design is such where the average person with DIY skill of 4 or 5 can setup one of our yurts. With. We do from time to time go and help setup the yurt. This is something to ask about when you are ordering your yurt.

    WE DO have a phone service that is always on. Call us “before” you start setting up your yurt. We will give this phone number a special ring tone. When this ring tone goes off, it tells us someone is setting up a yurt and needs help. This phone is answered 7 days a week.
    
    We have been able to talk someone over the phone how to setup their yurt when they lost their instructions manual. So if you need help, call us.
    
    But if you can’t setup the yurt yourself, we can help you find someone that can. Yurts of America is here for your entire project from start to finish.`},
    { question: "Where do you ship yurts?", answer: `We have shipped yurts to every part of North America including Hawaii, Alaska, and Mexico. We have shipped to England and mainland Europe.` },
]


export const yurtData = {
    16:
    {
        description: `
    16 foot diameter yurt
    tall walls (6' 10" to 7' 2")
    36/80 1/2 glass door with lock/dead bolt
    1 clear vinyl roll-up windows with screen
    Steel structure Brackets for frame structural points
    clear skylight dome
    10 year warranty exterior wall cover
    15 year warranty Duro-Last Roof cover
    `,
        sellingStatement: `
    Introducing the 16ft Yurt - stylish, durable, and functional, features tall walls for a spacious interior.
    Enjoy natural light and ventilation through the clear skylight dome. 
    Comes with a warranty for peace of mind and steel structure brackets for easy installation and stability. 
    Experience the benefits of yurt living today!
`},
    20:
    {
        description: `
    20 foot diameter yurt
    tall walls (6' 10" to 7' 2")
    36/80 1/2 glass door with lock/dead bolt
    2 clear vinyl roll-up windows with screen
    Steel structure Brackets for frame structural points
    clear skylight dome
    10 year warranty exterior wall cover
    15 year warranty Duro-Last Roof cover
    `,
        sellingStatement: `
    Our best-selling yurt size. This size is perfect for families, couples, solo adventurers. 
    looking for a comfortable and spacious living space while still being easy to set up? 
    Whether you're using it for camping trips, backyard getaways, or as a full-time residence, 
    the 20 foot yurt is the perfect choice for those looking for a unique and cozy living experience. So why wait? 
    Order your 20 foot yurt today and start enjoying the simple, connected way of life that only a yurt can provide!
`},

    24:
    {
        description: `
    24 foot diameter yurt
    tall walls (6' 10" to 7' 2")
    36/80 1/2 glass door with lock/dead bolt
    3 clear vinyl roll-up windows with screen
    Steel structure Brackets for frame structural points
    clear skylight dome
    10 year warranty exterior wall cover
    15 year warranty Duro-Last Roof cover
    `,
        sellingStatement: `
    The 24ft Yurt - the perfect blend of style and durability. 
    The sturdy frame and tall walls provide ample space for any use. 
    The clear skylight dome lets in natural light and provides ventilation. 
    Comes with a warranty for added peace of mind and steel structure brackets for easy installation and stability. 
    Elevate your living space with the 24ft Yurt today!
`},
    30:
    {
        description: `
    30 foot diameter yurt
    tall walls (6' 10" to 7' 2")
    36/80 1/2 glass door with lock/dead bolt
    4 clear vinyl roll-up windows with screen
    Steel structure Brackets for frame structural points
    clear skylight dome
    10 year warranty exterior wall cover
    15 year warranty Duro-Last Roof cover
    `,
        sellingStatement: `
    Experience the ultimate in yurt living with our 30ft Yurt! 
    Designed with a durable frame and tall walls, this spacious yurt provides limitless possibilities for any use. 
    The clear skylight dome allows for natural light and ventilation, creating a comfortable and healthy living environment.
    Enjoy added peace of mind with our comprehensive warranty and easy installation with steel structure brackets. 
    Don't settle for ordinary living - upgrade to the 30ft Yurt and elevate your lifestyle to new heights!
`}
}