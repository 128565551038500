import styles from "./gallerySectionStyles.module.css";
import yurt from "../../../imgs/gallerySection/yurt.png";
import video from "../../../imgs/gallerySection/video.png";
import Nav from "../../extensions/nav";
import { YOARoutes } from "../../../Constants/Constants";


const OurGallery = () => {
    const nav = Nav();
    return (
        <div>

            <div className={styles.headerText}>
                Our Gallery
            </div>
            <div className={styles.buttonSection}>

                <div className={styles.yurt}>
                    <img className={styles.sectionImgs} src={yurt} alt="yurt"></img>
                    <button className={styles.imgButton} onClick={() => nav(YOARoutes.gallery)}>See our Yurts</button>
                </div>

                <div className={styles.video}>
                    <img className={styles.sectionImgs} src={video} alt="video"></img>
                    <button className={styles.imgButton} onClick={() => nav(YOARoutes.videos)}>See our Videos</button>
                </div>
            </div>
        </div>
    )
}

export default OurGallery;