
const ScrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0
  });
  return null;
}


export default ScrollToTop;