import React from "react";
import styles from "./faqsStyles.module.css";
import outsideYurts25 from "../../imgs/gallerySection/outsidePhotos/outsideYurts25.jpeg";
import DropDown from "../dropDown/dropDown";
import { faqsData } from "../../Constants/Constants";

const FaqsPage = () => {
  const showQuestionsForm = () => {
    const dialog = document.getElementById(
      "ModalBackground"
    ) as HTMLDialogElement;

    dialog.style.display = "flex"
    dialog.showModal();
  };

  return (
    <div className={styles.container}>
      <div className={styles.topSection}>
        <div className={styles.topSectionHeader}>Yurts</div>
        <div className={styles.topSectionText}>
          Yurts are considered one of the best options for a semi-permanent
          residence. It is simpler than a home yet lavish as compared to a tent.
          Yurts are portable and they are best suited for adventure-loving
          people. A profound circular structure is built which is later covered
          with a coarse fabric. The roof part of a yurt is complex and it is
          erected in a way that protects you from windy and story weather. The
          origin of yurts is found to be in Central Asia, particularly Mongols
          used them. However, modern yurts are energy-efficient and comfy. They
          keep the temperature moderate in all weather conditions. Usually,
          bamboo wood is used to build a yurt and the floor is kept carpeted.
          Nowadays a range of yurts can be found that is permanent. It is the
          best option for those who love to travel and explore novelty in this
          world.
        </div>
      </div>
      <div className={styles.titleBG}>
        <img className={styles.title} alt="title" src={outsideYurts25}></img>
        <div className={styles.titleText}>FAQ'S.</div>
      </div>

      <div className={styles.faqColumn}>
        {React.Children.toArray(
          faqsData.map((faq) => (
            <>
              {DropDown(
                faq.question,
                <div className={styles.answerText}>{faq.answer}</div>
              )}
            </>
          ))
        )}
      </div>
      <div className={styles.questionSection}>
        <div className={styles.questionHeader}> Still have a question? </div>
        <div className={styles.questionBody}>
          Go to our question form and ask us anything or getter yet give us a
          call at (317) 850-6785
        </div>
        <button
          onClick={() => showQuestionsForm()}
          className={styles.questionButton}
        >
          Contact us!
        </button>
      </div>
    </div>
  );
};
export default FaqsPage;
