import { useState } from "react";
import styles from "./dropDownStyles.module.css";

const DropDown = (
  text: string,
  body: any,
  open: boolean = false
) => {
  const [isOpen, setOpen] = useState(open);

  return (
    <>
      <div
        className={styles.menuTrigger}
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        {text}
      </div>

      <div
        className={
          isOpen ? styles.dropdownMenuActive : styles.dropdownMenuInactive
        }
      >
        <DropdownItem />
      </div>
    </>
  );

  function DropdownItem() {
    return <div className={styles.dropdownItem}>{body}</div>;
  }
};

export default DropDown;
