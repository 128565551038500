import { HomePageText } from "../../../Constants/Constants";
import styles from "./aboutUsStyles.module.css"
import Nav from "../../extensions/nav";
import { YOARoutes } from "../../../Constants/Constants";

const AboutUsSection = () => {
    const nav = Nav();
    return (
        <div className={styles.container}>

            <div className={styles.aboutUsHeader}> ABOUT US</div>
            <div className={styles.seperator}></div>

            <div className={styles.aboutUs}>
                <div > {HomePageText.aboutUsHeader} <br /> <br /> {HomePageText.aboutUsText}</div>
                <button className={styles.learnMoreButton} onClick={() => nav(YOARoutes.about)}> Read more</button>
            </div>
        </div>
    )
}

export default AboutUsSection;