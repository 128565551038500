import { useState } from "react";
import styles from "./yurtWindowStyles.module.css";

const YurtWindowSection = (
  yurt: YurtType,
  setYurt: any,
) => {
  const [availableWindowPositions, setAvailableWindowPos] = useState([
    2, 3, 5, 6, 7, 8, 9, 10, 11, 13, 14,
  ]);
  let windowLimit = 7;

  switch (yurt.size) {
    case 16:
      windowLimit = 7;
      break;
    case 20:
      windowLimit = 7;
      break;
    case 24:
      windowLimit = 11;
      break;
    case 30:
      windowLimit = 15;
      break;
    default:
      break;
  }

  const addYurtWindow = () => {
    let position = 1;
    if (availableWindowPositions.length > 0) {
      position = availableWindowPositions[0];

      const newAvPos = availableWindowPositions.filter((val: any) =>
        val !== position
      );
      setAvailableWindowPos(newAvPos);
    }

    const newWindow: YurtWindow = {
      type: "vinyl",
      position: position,
      addons: [],
    };
    const newYurt: YurtType = {
      ...yurt,
      windows: [...yurt.windows, newWindow],
    };
    setYurt(newYurt);
  };

  const removeWindow = (index: number) => {
    if (index > 0) {
      const newWindows = yurt.windows;
      const newYurt: YurtType = {
        ...yurt,
        windows: newWindows.filter(
          (item) => item.position !== yurt.windows[index].position
        ),
      };
      setYurt(newYurt);

      setAvailableWindowPos([
        ...availableWindowPositions,
        yurt.windows[index].position,
      ]);
    }
  };

  const changeType = (event: any, index: number) => {
    const doors = yurt.doors;
    const door = doors[index];
    const newDoors = doors.map((item) => {
      if (item.position === door.position) {
        item.type = event.target.value;
      }
      return item;
    });
    const newYurt: YurtType = { ...yurt, doors: newDoors };
    setYurt(newYurt);
  };

  const changePos = (event: any, index: number) => {
    const newPosition = Number(event.target.value)
    let newWindows: any

    if (availableWindowPositions.includes(newPosition)) {
      const windows = yurt.windows;
      newWindows = windows.map((window, i) => {
        if (index === i) {
          const newAvPos = availableWindowPositions;
          newAvPos.push(window.position)
          setAvailableWindowPos(newAvPos);
          window.position = newPosition
        }
        return window
      })
      setAvailableWindowPos(availableWindowPositions.filter((pos) => { return pos !== newPosition }));
      const newYurtObj = { ...yurt, windows: newWindows }
      setYurt(newYurtObj)
    }

  };

  /* 
      window limit.
      16ft: 7
      20ft: 7
      24ft: 11
      30ft: 15
    */

  const WindowCard = (index: number, yurt: YurtType) => {
    const window: YurtWindow = yurt.windows[index];

    return (
      <div className={styles.doorCardContainer}>
        <div className={styles.header}>
          Window #{index + 1}
          <button
            onClick={() => removeWindow(index)}
            className={styles.minusBtn}
          >
            ✕
          </button>
        </div>
        <div className={styles.selectionContainer} key={index}>
          <div key={index}>
            {`Style: `}
            <select
              value={window.type}
              onChange={(event) => changeType(event, index)}
              id={`windowStyleSelection${index}`}
            >
              <option value={"vinyl"}> Clear Roll-Up Window </option>
              <option value={"glass"}> Glass Window </option>
            </select>
            <br />
            {`position: `}
            <select
              value={window.position}
              onChange={(event) => changePos(event, index)}
              id={`windowPositionSelection${index}`}
            >
              <option
                disabled={
                  availableWindowPositions.includes(1) === false ? true : false
                }
                value={1}
              >
                1
              </option>
              <option
                disabled={
                  availableWindowPositions.includes(2) === false ? true : false
                }
                value={2}
              >
                2
              </option>
              <option
                disabled={
                  availableWindowPositions.includes(3) === false ? true : false
                }
                value={3}
              >
                3
              </option>
              <option
                disabled={
                  availableWindowPositions.includes(4) === false ? true : false
                }
                value={4}
              >
                4
              </option>
              <option
                disabled={
                  availableWindowPositions.includes(5) === false ? true : false
                }
                value={5}
              >
                5
              </option>
              <option
                disabled={
                  availableWindowPositions.includes(6) === false ? true : false
                }
                value={6}
              >
                6
              </option>
              <option
                disabled={
                  availableWindowPositions.includes(7) === false ? true : false
                }
                value={7}
              >
                7
              </option>
              {yurt.size > 20 && (
                <option
                  disabled={
                    availableWindowPositions.includes(8) === false
                      ? true
                      : false
                  }
                  value={8}
                >
                  8
                </option>
              )}
              {yurt.size > 20 && (
                <option
                  disabled={
                    availableWindowPositions.includes(9) === false
                      ? true
                      : false
                  }
                  value={9}
                >
                  9
                </option>
              )}
              {yurt.size > 20 && (
                <option
                  disabled={
                    availableWindowPositions.includes(10) === false
                      ? true
                      : false
                  }
                  value={10}
                >
                  10
                </option>
              )}
              {yurt.size > 20 && (
                <option
                  disabled={
                    availableWindowPositions.includes(11) === false
                      ? true
                      : false
                  }
                  value={11}
                >
                  11
                </option>
              )}
              {yurt.size > 24 && (
                <option
                  disabled={
                    availableWindowPositions.includes(12) === false
                      ? true
                      : false
                  }
                  value={12}
                >
                  12
                </option>
              )}
              {yurt.size > 24 && (
                <option
                  disabled={
                    availableWindowPositions.includes(13) === false
                      ? true
                      : false
                  }
                  value={13}
                >
                  13
                </option>
              )}
              {yurt.size > 24 && (
                <option
                  disabled={
                    availableWindowPositions.includes(14) === false
                      ? true
                      : false
                  }
                  value={14}
                >
                  14
                </option>
              )}
              {yurt.size > 24 && (
                <option
                  disabled={
                    availableWindowPositions.includes(15) === false
                      ? true
                      : false
                  }
                  value={15}
                >
                  15
                </option>
              )}
            </select>
            <br />
            {"𝗘𝗫𝗧𝗥𝗔𝗦:"}
            <div>
              rain diverter: <input type="checkbox" id="rainDiverter"></input>
            </div>
            <div>
              Storm / Privacy Covers:
              <input type="checkbox" id="stormPrivacyCover"></input>
            </div>
            <div>
              stove kit: <input type="checkbox" id="stoveKit"></input>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {yurt.windows.map((window: YurtWindow, index: number) => (
        <div key={index}>{WindowCard(index, yurt)}</div>
      ))}
      {yurt.windows.length < windowLimit ? (
        <button className={styles.addDoorBtn} onClick={addYurtWindow}>
          Add Window
        </button>
      ) : undefined}
    </div>
  );
};

export default YurtWindowSection;

// const YurtWindowSection = (yurt: YurtType, setWindowPrice: React.Dispatch<React.SetStateAction<number>>) => {
//     const [rollUpWindowCount, setRollUpWindowCount] = useState(0)
//     const [glassWindowCount, setGlassWindowCount] = useState(0)
//     const [windowDiverterCount, setWindowDiverterCount] = useState(0)
//     const [stormWindowCount, setStormWindowCount] = useState(0)
//     const [stoveWindowCount, setStoveWindowCount] = useState(0)

//     const updatePrice = () => {
//         let tempPrice = (rollUpWindowCount * 190) + (glassWindowCount * 1350) + (windowDiverterCount * 50) + (stormWindowCount * 150) + (stoveWindowCount * 100)
//         setWindowPrice(tempPrice)
//     }

//     useEffect(() => {
//         updatePrice()
//     })

//     return(
//     <div className={styles.container}>
//         {NumberPicker("Clear Roll-Up Window", rollUpWindowCount, setRollUpWindowCount)}
//         {NumberPicker("Glass Window", glassWindowCount, setGlassWindowCount)}
//         {NumberPicker("rain diverter", windowDiverterCount, setWindowDiverterCount)}
//         {NumberPicker("Storm / Privacy Covers", stormWindowCount, setStormWindowCount)}
//         {NumberPicker("stove kit", stoveWindowCount, setStoveWindowCount)}
//     </div>
//     )

// }

// export default YurtWindowSection;
