import { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import styles from "./lowerCarouselStyles.module.css"

import CustomPrevArrow from "../customArrow/customPrevArrow";
import CustomNextArrow from "../customArrow/customNextArrow";

import insideYurts1 from '../../../../imgs/gallerySection/insidePhotos/insideYurts1.jpeg'
import insideYurts2 from '../../../../imgs/gallerySection/insidePhotos/insideYurts2.jpeg'
import outsideYurts1 from '../../../../imgs/gallerySection/outsidePhotos/outsideYurts1.jpeg'
import outsideYurts2 from '../../../../imgs/gallerySection/outsidePhotos/outsideYurts2.jpeg'
import outsideYurts4 from '../../../../imgs/gallerySection/outsidePhotos/outsideYurts4.jpeg'

const LowerCarousel = () => {

  const [photoIndex, setIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const isFirstRender = useRef(true);
  var imgSlides: any = [];


  const images = [
    insideYurts1,
    insideYurts2,
    outsideYurts1,
    outsideYurts2,
    outsideYurts4,
  ];

  const NextClick = () => {
    return;
  }

  const prevArrowProps = {
    onClick: NextClick,
    direction: "prev"
  }

  const nextArrowProps = {
    onClick: NextClick,
    direction: "next"
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 100,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    useTransform: false,
    adaptiveHeight: false,
    variableWidth: false,
    centerMode: false,
    arrows: true,
    prevArrow: <CustomPrevArrow {...prevArrowProps} />,
    nextArrow: <CustomNextArrow {...nextArrowProps} />,
    focusOnSelect: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return; // 👈️ return early if initial render
    }
    setIsOpen(true); // This is be executed when the state changes
  }, [photoIndex]);

  const setImg = (currentImg: number) => {
    setIndex(currentImg);
    setIsOpen(true);
  }


  images.map((img) => (
    imgSlides.push({ src: img })
  ))



  return (
    <div className={styles.container}>

      <Lightbox
        open={isOpen}
        close={() => setIsOpen(false)}
        slides={imgSlides}
        index={photoIndex}
      />

      <Slider {...settings} className={styles.carousel}  >
        <img className={styles.carouselImg} onClick={() => setImg(0)} src={insideYurts1} alt="slide1"></img>
        <img className={styles.carouselImg} onClick={() => setImg(1)} src={insideYurts2} alt="slide2"></img>
        <img className={styles.carouselImg} onClick={() => setImg(2)} src={outsideYurts1} alt="slide3"></img>
        <img className={styles.carouselImg} onClick={() => setImg(3)} src={outsideYurts2} alt="slide4"></img>
        <img className={styles.carouselImg} onClick={() => setImg(4)} src={outsideYurts4} alt="slide5"></img>
      </Slider>
    </div>
  );
}

export default LowerCarousel;