import { Cache, Mesh, Scene } from "three";
import basicWindow from "../../../imgs/modelFiles/common/BasicWindow.glb";

const LoadWindows = (
  windows: YurtWindow[],
  loader: any,
  scene: Scene,
  yurtSize: number
) => {
  let yurtScale: number = 1;

  const modelLoader = (url: string) => {
    return new Promise((resolve, reject) => {
      loader.load(url, (data: any) => resolve(data), undefined, reject);
    });
  };

  const getModel = async (type: string) => {
    let cachedModel = undefined;
    let model = undefined;

    cachedModel = Cache.get(`${type}Window`);

    if (cachedModel === undefined) {
      model = await modelLoader(basicWindow);
      Cache.add(`${type}Window`, model);
    } else {
      model = cachedModel;
    }

    return model;
  };

  switch (yurtSize) {
    case 16:
      yurtScale = 0.5333;
      break;
    case 20:
      yurtScale = 0.6667;
      break;
    case 24:
      yurtScale = 0.8;
      break;
    case 30:
      yurtScale = 1;
      break;

    default:
      break;
  }

  windows.map(async (window) => {
    const modelObject = await getModel(window.type);
    const model: Mesh = modelObject.scene;
    const clone = model.clone();
    switch (window.position) {
      case 1:
        var oldObj = scene.getObjectByName("windowModel1");
        if (oldObj) {
          scene.remove(oldObj);
        }
        clone.name = "windowModel1";
        clone.position.x += 0;
        clone.position.y += 0;
        clone.position.z -= 0;
        clone.scale.z = 0.9;
        clone.scale.y = 0.9;
        clone.scale.x = yurtScale;
        clone.rotation.set(0, -1.18, 0);
        break;
      case 2:
        oldObj = scene.getObjectByName("windowModel2");
        if (oldObj) {
          scene.remove(oldObj);
        }
        clone.name = "windowModel2";
        clone.position.x += 0;
        clone.position.y += 0;
        clone.position.z -= 0;
        clone.scale.z = 0.9;
        clone.scale.y = 0.9;
        clone.scale.x = yurtScale;
        clone.rotation.set(0, -0.78, 0);

        break;
      case 3:
        oldObj = scene.getObjectByName("windowModel3");
        if (oldObj) {
          scene.remove(oldObj);
        }
        clone.name = "windowModel3";
        clone.position.x += 0;
        clone.position.y += 0;
        clone.position.z -= 0;
        clone.scale.z = 0.9;
        clone.scale.y = 0.9;
        clone.scale.x = yurtScale;
        clone.rotation.set(0, -0.39, 0);

        break;
      case 4:
        oldObj = scene.getObjectByName("windowModel4");
        if (oldObj) {
          scene.remove(oldObj);
        }
        clone.name = "windowModel4";
        clone.position.x += 0;
        clone.position.y += 0;
        clone.position.z -= 0;
        clone.scale.z = 0.9;
        clone.scale.y = 0.9;
        clone.scale.x = yurtScale;
        clone.rotation.set(0, 0, 0);
        break;
      case 5:
        oldObj = scene.getObjectByName("windowModel5");
        if (oldObj) {
          scene.remove(oldObj);
        }
        clone.name = "windowModel5";
        clone.position.x += 0;
        clone.position.y += 0;
        clone.position.z -= 0;
        clone.scale.z = 0.9;
        clone.scale.y = 0.9;
        clone.scale.x = yurtScale;
        clone.rotation.set(0, 0.4, 0);
        break;
      case 6:
        oldObj = scene.getObjectByName("windowModel6");
        if (oldObj) {
          scene.remove(oldObj);
        }
        clone.name = "windowModel6";
        clone.position.x += 0;
        clone.position.y += 0;
        clone.position.z -= 0;
        clone.scale.z = 0.9;
        clone.scale.y = 0.9;
        clone.scale.x = yurtScale;
        clone.rotation.set(0, 0.79, 0);
        break;
      case 7:
        oldObj = scene.getObjectByName("windowModel7");
        if (oldObj) {
          scene.remove(oldObj);
        }
        clone.name = "windowModel7";
        clone.position.x += 0;
        clone.position.y += 0;
        clone.position.z -= 0;
        clone.scale.z = 0.9;
        clone.scale.y = 0.9;
        clone.scale.x = yurtScale;
        clone.rotation.set(0, 1.18, 0);

        break;
      case 8:
        oldObj = scene.getObjectByName("windowModel8");
        if (oldObj) {
          scene.remove(oldObj);
        }
        clone.name = "windowModel8";
        clone.position.x += 0;
        clone.position.y += 0;
        clone.position.z -= 0;
        clone.scale.z = 0.9;
        clone.scale.y = 0.9;
        clone.scale.x = yurtScale;
        clone.rotation.set(0, 1.57, 0);

        break;
      case 9:
        oldObj = scene.getObjectByName("windowModel9");
        if (oldObj) {
          scene.remove(oldObj);
        }
        clone.name = "windowModel9";
        clone.position.x += 0;
        clone.position.y += 0;
        clone.position.z -= 0;
        clone.scale.z = 0.9;
        clone.scale.y = 0.9;
        clone.scale.x = yurtScale;
        clone.rotation.set(0, 1.97, 0);

        break;
      case 10:
        oldObj = scene.getObjectByName("windowModel10");
        if (oldObj) {
          scene.remove(oldObj);
        }
        clone.name = "windowModel10";
        clone.position.x += 0;
        clone.position.y += 0;
        clone.position.z -= 0;
        clone.scale.z = 0.9;
        clone.scale.y = 0.9;
        clone.scale.x = yurtScale;
        clone.rotation.set(0, 2.36, 0);

        break;
      case 11:
        oldObj = scene.getObjectByName("windowModel11");
        if (oldObj) {
          scene.remove(oldObj);
        }
        clone.name = "windowModel11";
        clone.position.x += 0;
        clone.position.y += 0;
        clone.position.z -= 0;
        clone.scale.z = 0.9;
        clone.scale.y = 0.9;
        clone.scale.x = yurtScale;
        clone.rotation.set(0, 2.75, 0);

        break;
      case 12:
        oldObj = scene.getObjectByName("windowModel12");
        if (oldObj) {
          scene.remove(oldObj);
        }
        clone.name = "windowModel12";
        clone.position.x += 0;
        clone.position.y += 0;
        clone.position.z -= 0;
        clone.scale.z = 0.9;
        clone.scale.y = 0.9;
        clone.scale.x = yurtScale;
        clone.rotation.set(0, 3.15, 0);

        break;
      case 13:
        oldObj = scene.getObjectByName("windowModel13");
        if (oldObj) {
          scene.remove(oldObj);
        }
        clone.name = "windowModel13";
        clone.position.x += 0;
        clone.position.y += 0;
        clone.position.z -= 0;
        clone.scale.z = 0.9;
        clone.scale.y = 0.9;
        clone.scale.x = yurtScale;
        clone.rotation.set(0, 3.54, 0);

        break;
      case 14:
        oldObj = scene.getObjectByName("windowModel14");
        if (oldObj) {
          scene.remove(oldObj);
        }
        clone.name = "windowModel14";
        clone.position.x += 0;
        clone.position.y += 0;
        clone.position.z -= 0;
        clone.scale.z = 0.9;
        clone.scale.y = 0.9;
        clone.scale.x = yurtScale;
        clone.rotation.set(0, 3.94, 0);

        break;
      case 15:
        oldObj = scene.getObjectByName("windowModel15");
        if (oldObj) {
          scene.remove(oldObj);
        }
        clone.name = "windowModel15";
        clone.position.x += 0;
        clone.position.y += 0;
        clone.position.z -= 0;
        clone.scale.z = 0.9;
        clone.scale.y = 0.9;
        clone.scale.x = yurtScale;
        clone.rotation.set(0, 4.32, 0);

        break;
      default:
        break;
    }
    scene.add(clone);
  });
  return;
};

export default LoadWindows;
