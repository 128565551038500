import { Color, MeshStandardMaterial, Mesh } from "three";
import GetContainer from "../extensions/getContainer";

const ChangeModelColor = (model: any, color: YurtColor) => {
  model.userData.isContainer = true;
  let bodyContainer = GetContainer(model);
  bodyContainer.traverse((child: Mesh) => {
    if (child.material) {
      let mat = child.material as MeshStandardMaterial;
      const newColor = new Color(color.hex).convertSRGBToLinear();
      mat.color = newColor;
      child.material = mat;
    }
  });
};

export default ChangeModelColor;
