import React, { useState } from "react";
import styles from "./yurtSizeStyles.module.css";
import { yurtData } from "../../../../Constants/Constants";
import ScaleModel from "../../../extensions/scaleModel";
import { Scene } from "three";

const YurtSizeSection = (
  yurt: YurtType,
  setYurt: React.Dispatch<React.SetStateAction<YurtType>>,
  setYurtPrice: React.Dispatch<React.SetStateAction<number>>,
  mainScene: Scene | null,
  basePricing: any
) => {
  const [selection, setSelection] = useState(30);
  const [description, setDescription] = useState(yurtData[30].description);
  const [sellingStatement, setSellingStatement] = useState(
    yurtData[30].sellingStatement
  );
  const yurtSizeClickHandle = (selectedOption: number) => {
    setSelection(selectedOption);
    setYurt({ ...yurt, size: selectedOption });

    switch (selectedOption) {
      case 16:
        setDescription(yurtData[16].description);
        setSellingStatement(yurtData[16].sellingStatement);
        ScaleModel(16, yurt, setYurt, mainScene);
        setYurtPrice(basePricing.SIXTEEN.BASE);
        break;
      case 20:
        setDescription(yurtData[20].description);
        setSellingStatement(yurtData[20].sellingStatement);
        ScaleModel(20, yurt, setYurt, mainScene);
        setYurtPrice(basePricing.TWENTY.BASE);
        break;
      case 24:
        setDescription(yurtData[24].description);
        setSellingStatement(yurtData[24].sellingStatement);
        ScaleModel(24, yurt, setYurt, mainScene);
        setYurtPrice(basePricing.TWENTYFOUR.BASE);
        break;
      case 30:
        setDescription(yurtData[30].description);
        setSellingStatement(yurtData[30].sellingStatement);
        ScaleModel(30, yurt, setYurt, mainScene);
        setYurtPrice(basePricing.THIRTY.BASE);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className={styles.radioContainer}>
        <form className={styles.radioGroup}>
          <input
            type="radio"
            name="sizeRadio"
            value="16 ft"
            checked={selection === 16 ? true : false}
            onChange={() => yurtSizeClickHandle(16)}
          />
          16 ft <br /> <br />
          <input
            type="radio"
            name="sizeRadio"
            value="20 ft"
            checked={selection === 20 ? true : false}
            onChange={() => yurtSizeClickHandle(20)}
          />
          20 ft <br /> <br />
          <input
            type="radio"
            name="sizeRadio"
            value="24 ft"
            checked={selection === 24 ? true : false}
            onChange={() => yurtSizeClickHandle(24)}
          />
          24 ft <br /> <br />
          <input
            type="radio"
            name="sizeRadio"
            value="30 ft"
            checked={selection === 30 ? true : false}
            onChange={() => yurtSizeClickHandle(30)}
          />
          30 ft
        </form>
      </div>
      <div className={styles.description}>
        <div className={styles.packageDetails}>{description}</div>
        <div className={styles.sellingStatement}>{sellingStatement}</div>
      </div>
    </>
  );
};

export default YurtSizeSection;
