import { Cache, Mesh, Scene } from "three";
import single from "../../../imgs/modelFiles/common/singleDoor.glb";
import double from "../../../imgs/modelFiles/common/doubleDoor.glb";

const LoadDoors = (
  doors: YurtDoor[],
  loader: any,
  scene: Scene,
  yurtSize: number
) => {
  let yurtScale: number = 1;

  switch (yurtSize) {
    case 16:
      yurtScale = 0.5333;
      break;
    case 20:
      yurtScale = 0.6667;
      break;
    case 24:
      yurtScale = 0.8;
      break;
    case 30:
      yurtScale = 1;
      break;

    default:
      break;
  }

  const modelLoader = (url: string) => {
    return new Promise((resolve, reject) => {
      loader.load(url, (data: any) => resolve(data), undefined, reject);
    });
  };

  const getModel = async (type: string) => {
    let cachedModel = undefined;
    let model = undefined;

    cachedModel = Cache.get(`${type}Door`);

    if (cachedModel === undefined) {
      model = await modelLoader(type === "single" ? single : double);
      Cache.add(`${type}Door`, model);
    } else {
      model = cachedModel;
    }

    return model;
  };

  doors.map(async (door) => {
    const modelObject = await getModel(door.type);
    const model: Mesh = modelObject.scene;
    model.renderOrder = -1;

    switch (door.position) {
      case 3:
        var oldObj = scene.getObjectByName("doorModel3");
        const model3 = model.clone();
        if (oldObj) {
          scene.remove(oldObj);
        }
        model3.name = "doorModel3";
        scene.add(model3);
        model3.position.x = 0;
        model3.position.y = 0;
        model3.position.z = 0;
        model3.scale.z = yurtScale;
        model3.rotation.set(0, 1.59, 0);
        break;
      case 6:
        oldObj = scene.getObjectByName("doorModel6");
        if (oldObj) {
          scene.remove(oldObj);
        }
        model.name = "doorModel6";
        model.position.z = 0;
        model.scale.z = yurtScale;
        scene.add(model);
        break;
      case 9:
        oldObj = scene.getObjectByName("doorModel9");
        if (oldObj) {
          scene.remove(oldObj);
        }
        const model9 = model.clone();
        model9.name = "doorModel9";
        scene.add(model9);
        model9.position.x = 0;
        model9.position.y = 0;
        model9.position.z = 0;
        model9.scale.z = yurtScale;
        model9.rotation.set(0, -1.55, 0);
        break;
      case 12:
        oldObj = scene.getObjectByName("doorModel12");
        if (oldObj) {
          scene.remove(oldObj);
        }
        const model12 = model.clone();
        model12.name = "doorModel12";
        scene.add(model12);
        model12.position.x = 0;
        model12.position.y = 0;
        model12.position.z = 0;
        model12.scale.z = yurtScale;
        model12.rotation.set(0, 3.16, 0)
        break;

      default:
        break;
    }
  });
  return;
};

export default LoadDoors;
