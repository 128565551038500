import React, { useState, useEffect } from "react";
import styles from "./yurtStructureStyles.module.css";
import YOACheckBox from "../../../extensions/checkbox/YOACheckBox";

const YurtStructureSection = (yurtSize: number, setStructurePrice: React.Dispatch<React.SetStateAction<number>>) => {
    const [comfortSelection, editSelection] = useState<string[]>([])
    const [total, setTotal] = useState(0)

    const { yoaCheckbox: twox6, } = YOACheckBox("2x6 Rafters", () => clickHandle("2x6"))
    const { yoaCheckbox: rafter, } = YOACheckBox("Rafter Blocking", () => clickHandle("rafter"))
    const { yoaCheckbox: snow, } = YOACheckBox("std snow/wind kit", () => clickHandle("snow"))
    const { yoaCheckbox: base, } = YOACheckBox("base blocking", () => clickHandle("base"))

    const updatePrice = () => {

        let tempTotal = 0;

        let twoBy6 = false
        let rafter = false
        let snow = false
        let base = false

        for (let i = 0; i < comfortSelection.length; i++) {
            if (comfortSelection[i] === "2x6") {
                twoBy6 = true
            }
            if (comfortSelection[i] === "rafter") {
                rafter = true
            }
            if (comfortSelection[i] === "snow") {
                snow = true
            }
            if (comfortSelection[i] === "base") {
                base = true
            }
        }


        const addPrice = (twoPrice: number, rafterPrice: number, snowPrice: number, basePrice: number) => {
            if (twoBy6) {
                tempTotal += twoPrice
            }
            if (rafter) {
                tempTotal += rafterPrice
            }
            if (snow) {
                tempTotal += snowPrice
            }
            if (base) {
                tempTotal += basePrice
            }
        }

        switch (yurtSize) {
            case 16:
                addPrice(699, 150, 599, 499)
                break;
            case 20:
                addPrice(799, 300, 985, 799)
                break;
            case 24:
                addPrice(200, 500, 1150, 999)
                break;
            case 30:
                addPrice(1100, 700, 1375, 975)
                break;
            default:
                break;
        }
        setTotal(tempTotal)
        setStructurePrice(tempTotal)
    }

    const clickHandle = (userSelection: string) => {
        const index = comfortSelection.indexOf(userSelection)
        if (index === -1) {
            editSelection([...comfortSelection, userSelection])
        } else {
            editSelection(comfortSelection.filter(item => item !== userSelection))
        }
        updatePrice()
    }


    useEffect(() => {
        updatePrice()
    })


    return (
        <div className={styles.structureContainer}>
            <div className={styles.structureGroup}>
                {twox6}<br /> <br />
                {rafter} <br /> <br />
                {snow} <br /> <br />
                {base} <br /> <br />
            </div>
            <div className={styles.structureTotal}>structure total: ${total}</div>
        </div>
    )
}

export default YurtStructureSection;