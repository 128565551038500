import { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import Lightbox from "yet-another-react-lightbox";
import styles from "./categoryDisplayStyles.module.css";
import { outsideImgs, insideImgs, loftImgs, optionsImgs, specialProjectImgs, underConstructionImgs, galleryCategories } from "../../../Constants/Constants";

const CategoryDisplay = () => {
    const location = useLocation();
    const selectedCategory = location.state.category;

    const [photoIndex, setIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const isFirstRender = useRef(true);

    var data = outsideImgs;
    var imgSlides: any = [];

    switch (selectedCategory) {
        case galleryCategories.outside:
            data = outsideImgs;
            break;
        case galleryCategories.inside:
            data = insideImgs;
            break;
        case galleryCategories.loft:
            data = loftImgs;
            break;
        case galleryCategories.options:
            data = optionsImgs;
            break;
        case galleryCategories.special:
            data = specialProjectImgs;
            break;
        case galleryCategories.construction:
            data = underConstructionImgs;
            break;
    }

    data.map((img) => (
        imgSlides.push({ src: img })
    ))



    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return; // 👈️ return early if initial render
        }
        setIsOpen(true); // This is be executed when the state changes
    }, [photoIndex]);

    const setImg = (currentImg: number) => {
        setIndex(currentImg);
        setIsOpen(true);
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                {selectedCategory.toUpperCase()}.
            </div>

            <div className={styles.imgGrid}>
                {data.map((image, index) => (
                    <div key={index} className={styles.imgContainer}>
                        <img key={index} className={styles.cardImg} onClick={() => setImg(index)} src={image} alt="" />
                    </div>))}
            </div>

            {isOpen && (
                <Lightbox
                    open={isOpen}
                    close={() => setIsOpen(false)}
                    slides={imgSlides}
                />
            )}

        </div>
    )
}

export default CategoryDisplay;