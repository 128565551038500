import styles from "./categorySelectionStyles.module.css";
import YoutubeEmbed from "../youtubeEmbed/youtubeEmbed";

const VideoCategorySelection = () => {

    return (
        <div>

            <div className={styles.parallax} >
                <div className={styles.content}>
                    <div className={styles.title}>VIDEOS.</div>
                </div>
            </div>

            <div className={styles.videoGrid}>
                <div className={styles.videos}>
                    <YoutubeEmbed embedId="uWp38K-AM9E" />
                </div>
                <div className={styles.videos}>
                    <YoutubeEmbed embedId="DdY0U-tNTII" />
                </div>
                <div className={styles.videos}>
                    <YoutubeEmbed embedId="f2qPfJOn_XE" />
                </div>
                <div className={styles.videos}>
                    <YoutubeEmbed embedId="i78XOsY0eOE" />
                </div>
                <div className={styles.videos}>
                    <YoutubeEmbed embedId="s-9bdvl0qOE" />
                </div>
                <div className={styles.videos}>
                    <YoutubeEmbed embedId="25CG3Zl8rVo" />
                </div>
                <div className={styles.videos}>
                    <YoutubeEmbed embedId="qrjY_ZX9MXk" />
                </div>
                <div className={styles.videos}>
                    <YoutubeEmbed embedId="6F6Xt9wUn4Q" />
                </div>

            </div>
        </div>
    )
}


export default VideoCategorySelection
