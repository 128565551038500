import styles from "./headerStyles.module.css";
import logo from "../../imgs/icons/logo.png";
import { YOARoutes } from "../../Constants/Constants";
import Nav from "../extensions/nav";

const Header = () => {
  const nav = Nav();

  const showQuestionsForm = () => {
    const dialog = document.getElementById(
      "ModalBackground"
    ) as HTMLDialogElement;
    dialog.style.display = "flex"
    dialog.showModal();
  };

  return (
    <div className={styles.headerStyle}>
      <img
        onClick={() => {
          nav(YOARoutes.home);
        }}
        className={styles.logo}
        src={logo}
        alt="error"
      />
      <div className={styles.buttonGroup}>
        <button
          onClick={() => {
            nav(YOARoutes.home);
          }}
          className={styles.yoaButton}
        >
          HOME
        </button>
        <button
          onClick={() => {
            nav(YOARoutes.gallery);
          }}
          className={styles.yoaButton}
        >
          GALLERY
        </button>
        <button
          onClick={() => {
            nav(YOARoutes.videos);
          }}
          className={styles.yoaButton}
        >
          VIDEOS
        </button>
        <button
          onClick={() => {
            nav(YOARoutes.about);
          }}
          className={styles.yoaButton}
        >
          ABOUT
        </button>
        <button
          onClick={() => {
            nav(YOARoutes.faqs);
          }}
          className={styles.yoaButton}
        >
          FAQ'S
        </button>
        <button
          onClick={() => showQuestionsForm()}
          className={styles.yoaButton}
        >
          CONTACT US
        </button>
        <button
          onClick={() => {
            nav(YOARoutes.getAQuote);
          }}
          className={styles.yoaButton}
        >
          PRICING
        </button>
      </div>
    </div>
  );
};

export default Header;
