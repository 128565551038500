import React from "react";
import styles from "./yurtShippingStyles.module.css";
const YurtShippingSection = (yurtShipping: string, setYurtShipping: React.Dispatch<React.SetStateAction<string>>) => {

    const yurtSizeClickHandle = (selection: string) => {
        setYurtShipping(selection)
    }


    return (

        <div className={styles.radioContainer}>
            <div className={styles.radioGroup}>
                <input type="radio" name="sizeRadio" value="Continental US" checked={yurtShipping === "continental" ? true : false} onChange={() => yurtSizeClickHandle("continental")} /> Continental US <br /> <br />
                <input type="radio" name="sizeRadio" value="International / Alaska / Hawaii" checked={yurtShipping === "international" ? true : false} onChange={() => yurtSizeClickHandle("international")} /> International / Alaska / Hawaii <br /> <br />
            </div>
        </div>

    )
}

export default YurtShippingSection;