import React from "react";
import prev from '../../../../imgs/icons/prev.png'
import styles from './arrowStyles.module.css';


export interface Props {
    onClick: React.MouseEventHandler<HTMLDivElement>;
}

const CustomPrevArrow = (props: Props) => {

    return (
        <div className={styles.prev} onClick={props.onClick}>
            <img alt="prev" src={prev}></img>
        </div>
    );
}

export default CustomPrevArrow;