import React, { useState, useEffect } from "react";
import styles from "./yurtDomeStyles.module.css";
import YOACheckBox from "../../../extensions/checkbox/YOACheckBox";

const YurtDomeSection = (
  yurtSize: number,
  setDomePrice: React.Dispatch<React.SetStateAction<number>>
) => {
  const [comfortSelection, editSelection] = useState<string[]>([]);
  const [total, setTotal] = useState(0);

  const { yoaCheckbox: bug } = YOACheckBox("Bug ring", () =>
    clickHandle("bug")
  );
  const { yoaCheckbox: opener } = YOACheckBox("Dome opener", () =>
    clickHandle("opener")
  );
  const { yoaCheckbox: fan } = YOACheckBox("fan support", () =>
    clickHandle("fan")
  );
  const { yoaCheckbox: screen } = YOACheckBox("shade screen", () =>
    clickHandle("screen")
  );
  const { yoaCheckbox: tinted } = YOACheckBox("tinted dome", () =>
    clickHandle("tinted")
  );

  const updatePrice = () => {
    let tempTotal = 0;

    let bug = false;
    let opener = false;
    let fan = false;
    let screen = false;
    let tinted = false;

    for (let i = 0; i < comfortSelection.length; i++) {
      if (comfortSelection[i] === "bug") {
        bug = true;
      }
      if (comfortSelection[i] === "opener") {
        opener = true;
      }
      if (comfortSelection[i] === "fan") {
        fan = true;
      }
      if (comfortSelection[i] === "screen") {
        screen = true;
      }
      if (comfortSelection[i] === "tinted") {
        tinted = true;
      }
    }

    const addPrice = (
      bugPrice: number,
      openerPrice: number,
      fanPrice: number,
      screenPrice: number,
      tintedPrice: number
    ) => {
      if (bug) {
        tempTotal += bugPrice;
      }
      if (opener) {
        tempTotal += openerPrice;
      }
      if (fan) {
        tempTotal += fanPrice;
      }
      if (screen) {
        tempTotal += screenPrice;
      }
      if (tinted) {
        tempTotal += tintedPrice;
      }
    };

    addPrice(150, 175, 50, 135, 200);

    setTotal(tempTotal);
    setDomePrice(tempTotal);
  };

  const clickHandle = (userSelection: string) => {
    const index = comfortSelection.indexOf(userSelection);
    if (index === -1) {
      editSelection([...comfortSelection, userSelection]);
    } else {
      editSelection(comfortSelection.filter((item) => item !== userSelection));
    }
    updatePrice();
  };

  useEffect(() => {
    updatePrice();
  });

  return (
    <div className={styles.structureContainer}>
      <div className={styles.structureGroup}>
        {bug}
        <br /> <br />
        {opener} <br /> <br />
        {fan} <br /> <br />
        {screen} <br /> <br />
        {tinted} <br /> <br />
      </div>
      <div className={styles.structureTotal}>Dome total: ${total}</div>
    </div>
  );
};

export default YurtDomeSection;
