import styles from "./footerStyles.module.css";

const FooterSection = () => {

    return (
        <div id="footer" className={styles.footerSection}>
            <div className={styles.content}>
                <div className={styles.aboutSection}>
                    <h2>
                        <div className={styles.line} />
                        ABOUT
                        <div className={styles.line} />
                        <div className={styles.aboutText}>
                            You’ve come to the right place for the best yurts for sale anywhere! <br /> <br /> Yurts of America is the last of the handmade custom yurt companies, and take pride in providing yurts for everything from a quiet family retreat to major corporate campgrounds.
                        </div>
                    </h2>
                </div>

                <div className={styles.contactSection}>
                    <h2>
                        <div className={styles.line} />
                        CONTACT US
                        <div className={styles.line} />
                        <div className={styles.contactText}>
                            <a className={styles.directionLink} rel="noreferrer" target="_blank" href="https://www.google.com/maps/place/Yurts+of+America+Inc/@39.8360236,-86.0340781,15z/data=!4m5!3m4!1s0x0:0x8755f073a7b34398!8m2!3d39.8360236!4d-86.0340781" >
                                Yurts of America
                                4375 Sellers Street
                                Indianapolis, IN 46226</a>
                            <br />
                            <br />
                            (317) 377-9878
                            info@yurtsofamerica.com
                        </div>
                    </h2>
                </div>

                <div className={styles.hourSection}>
                    <h2>
                        <div className={styles.line} />
                        OPENING HOURS
                        <div className={styles.line} />

                        <div className={styles.hourText}>
                            Monday – Friday 9:30 – 3:00 est. <br /> All other times by appointment
                        </div>
                    </h2>
                </div>
                <div className={styles.copyRight}>© COPYRIGHT 2023. ALL RIGHTS RESERVED BY YURTS OF AMERICA</div>
            </div>
        </div>
    )
}

export default FooterSection;