import React from "react";
import styles from "./colorStyles.module.css";

const ColorSelection = (isTopUpgraded: Boolean, colors: YurtColor[], defaultSelection: YurtColor, setSelection: (color: YurtColor) => void, clickHandler: any = undefined) => {

    const ColorBox = () => {

        const click = (color: YurtColor) => {
            setSelection(color)

            if (clickHandler !== undefined) {
                clickHandler()
            }
        }

        return (
            <>
                {React.Children.toArray(
                    colors.map((color) => (
                        <>
                            <div className={styles.colorBox} style={{ backgroundColor: color.hex }} onClick={() => click(color)}>
                                <span className={styles.toolTipText}>{color.name}</span>
                                {defaultSelection.hex === color.hex && !isTopUpgraded && <div className={styles.check}>✓</div>}
                            </div>
                        </>
                    )
                    ))}
            </>
        )
    }

    return (
        <div className={styles.container}>
            {ColorBox()}
        </div>
    )

}


export default ColorSelection;