import { useNavigate, NavigateOptions } from 'react-router-dom'
import ScrollToTop from "./scrollToTop";

const Nav = () => {
    const navigate = useNavigate()

    const nav = (route: string, options?: NavigateOptions | undefined) => {
        navigate(route, options);
        ScrollToTop();
    }
    return nav

}

export default Nav;