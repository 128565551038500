import { yurtColors } from "../../../../Constants/Constants";

// type YurtDoor = {
//     type: string
//     position: number
//     addons: string[]
//   }

// type YurtWindow = {
//     type: string
//     position: number
//     addons: string[]
//   }

const Yurt: YurtType = {
  size: 30,
  topColor: yurtColors.top.tan,
  bottomColor: yurtColors.bottom.forestGreen,
  doors: [{ type: "single", position: 6, addons: [] }],
  windows: [
    { type: "vinyl", position: 1, addons: [] },
    { type: "vinyl", position: 4, addons: [] },
    { type: "vinyl", position: 12, addons: [] },
    { type: "vinyl", position: 15, addons: [] },
  ],
};

export default Yurt;
