import { useState } from "react";
import styles from "./yurtDoorStyles.module.css";

const YurtDoorSection = (
  yurt: YurtType,
  setYurt: any,
) => {
  const [availableDoorPositions, setAvailableDoorPos] = useState([3, 9, 12]);

  const AddYurtDoor = () => {
    let position = 6;
    if (availableDoorPositions.length > 0) {
      position = availableDoorPositions[0];

      const newAvPos = availableDoorPositions.filter((val: any) => {
        return val !== position
      });
      setAvailableDoorPos(newAvPos);
    }

    const newDoor: YurtDoor = {
      type: "single",
      position: position,
      addons: [],
    };
    const newYurt: YurtType = { ...yurt, doors: [...yurt.doors, newDoor] };
    setYurt(newYurt);
  };

  const RemoveDoor = (index: number) => {
    if (index > 0) {
      const newDoors = yurt.doors;
      const newYurt: YurtType = {
        ...yurt,
        doors: newDoors.filter(
          (item) => item.position !== yurt.doors[index].position
        ),
      };
      setYurt(newYurt);

      setAvailableDoorPos([
        ...availableDoorPositions,
        yurt.doors[index].position,
      ]);
    }
  };

  const ChangeType = (event: any, index: number) => {
    const doors = yurt.doors;
    const door = doors[index];
    const newDoors = doors.map((item) => {
      if (item.position === door.position) {
        item.type = event.target.value;
      }
      return item;
    });
    const newYurt: YurtType = { ...yurt, doors: newDoors };
    setYurt(newYurt);
  };

  const ChangePos = (event: any, index: number) => {
    const newPosition = Number(event.target.value)
    let newDoors: any

    if (availableDoorPositions.includes(newPosition)) {
      const doors = yurt.doors;
      newDoors = doors.map((door, i) => {
        if (index === i) {
          const newAvPos = availableDoorPositions;
          newAvPos.push(door.position)
          setAvailableDoorPos(newAvPos);
          door.position = newPosition
        }
        return door
      })
      setAvailableDoorPos(availableDoorPositions.filter((pos) => { return pos !== newPosition }));
      const newYurtObj = { ...yurt, doors: newDoors }
      setYurt(newYurtObj)
    }

  };

  const DoorCard = (index: number, yurt: YurtType) => {
    const door: YurtDoor = yurt.doors[index];
    return (
      <div className={styles.doorCardContainer}>
        <div className={styles.header}>
          Door #{index + 1}
          <button onClick={() => RemoveDoor(index)} className={styles.minusBtn}>
            ✕
          </button>
        </div>
        <div className={styles.selectionContainer} key={index}>
          {`Style: `}
          <select
            value={door.type}
            onChange={(event) => ChangeType(event, index)}
            id={`doorStyleSelection${index}`}
          >
            <option value={`single`}> Standard (36x80) Door</option>
            <option value={`double`}> Double (72x80) Door </option>
          </select>
          {`position: `}
          <select
            value={door.position}
            disabled={door.position === 6}
            onChange={(event) => ChangePos(event, index)}
            id={`doorPositionSelection${index}`}
          >
            <option value={3} disabled={!availableDoorPositions.includes(3)} > 3:00 </option>
            <option value={6} disabled={!availableDoorPositions.includes(6)}> 6:00 </option>
            <option value={9} disabled={!availableDoorPositions.includes(9)}> 9:00 </option>
            <option value={12} disabled={!availableDoorPositions.includes(12)}> 12:00 </option>
          </select>
          {
            // eslint-disable-next-line
            "𝗘𝗫𝗧𝗥𝗔𝗦:"
          }
          <br />
          <div>
            Door Awning: <input type="checkbox" id="doorAwning"></input>
          </div>
          <div>
            Handicap Handle: <input type="checkbox" id="handicapHandle"></input>
          </div>
          <div>
            Outswings: <input type="checkbox" id="outswing"></input>
          </div>
          <div>
            Rain Diverter: <input type="checkbox" id="rainDiverter"></input>
          </div>
          <div>
            Screen Doors: <input type="checkbox" id="ScreenDoor"></input>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {yurt.doors.map((_: YurtDoor, index: number) => (
        <div key={index}>{DoorCard(index, yurt)}</div>
      ))}
      {yurt.doors.length < 4 ? (
        <button className={styles.addDoorBtn} onClick={AddYurtDoor}>
          Add Door
        </button>
      ) : undefined}
    </div>
  );
};

export default YurtDoorSection;
