import { useEffect, useState, useCallback } from "react";
import DropDown from "../dropDown/dropDown";
import styles from "./quotePageStyles.module.css";
import YurtSizeSection from "./sections/yurtSizeSection/yurtSize";
import YurtStructureSection from "./sections/yurtStructureSection/yurtStructure";
import YurtComfortSection from "./sections/yurtComfortSection/yurtComfort";
import YurtDomeSection from "./sections/yurtDomeSection/yurtDomeSection";
import YurtColorSection from "./sections/yurtColorSection/yurtColorSection";
import YurtWindowSection from "./sections/yurtWindowSection/yurtWindowSection";
import YurtDoorSection from "./sections/yurtDoorSection/yurtDoorSection";
import YurtScene from "./sections/3DRender/yurtScene";
import YurtShippingSection from "./sections/yurtShipping/yurtShipping";
import LoadCommon from "../extensions/modelLoaders/loadCommon";
import Yurt from "./sections/3DRender/yurt";
import ChangeModelColor from "../extensions/changeModelColor";
import LoadDoors from "../extensions/modelLoaders/loadDoors";

import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, get } from "firebase/database";
import { Scene } from "three";
import LoadWindows from "../extensions/modelLoaders/loadWindows";

const firebaseConfig = {
  apiKey: process.env.G_API_KEY,
  authDomain: "yoapricing.firebaseapp.com",
  databaseURL: "https://yoapricing-default-rtdb.firebaseio.com",
  projectId: "yoapricing",
  storageBucket: "yoapricing.appspot.com",
  messagingSenderId: "14878006230",
  appId: process.env.G_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const QuotePage = () => {
  const [yurt, setYurt] = useState<YurtType>(Yurt);

  const [mainScene, setMainScene] = useState<Scene | null>(null);
  const [loader, setLoader] = useState<any | null>(null);
  const [isSceneAdded, setIsSceneAdded] = useState(false);
  const [basePrices, setBasePrices] = useState(null);
  const [sizePrice, setSizePrice] = useState(17735);
  const [structurePrice, setStructurePrice] = useState(0);
  const [comfortPrice, setComfortPrice] = useState(0);
  const [windowsPrice, setWindowsPrice] = useState(0);
  const [doorsPrice, setDoorsPrice] = useState(0);
  const [domePrice, setDomePrice] = useState(0);
  const [yurtShipping, setYurtShipping] = useState("continental");
  const [LoadedModels, setLoadedModels] = useState(false);

  if (basePrices === null) {
    const dbRef = ref(getDatabase(app));
    get(child(dbRef, `//PricingBySize`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setBasePrices(snapshot.val());
          // setSizePrice(snapshot.val().THIRTY);
        } else {
          alert("Could not find pricing data");
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  const UpdateDoorRender = useCallback((mainScene: any) => {
    mainScene.remove(mainScene.getObjectByName("doorModel6")!);
    mainScene.remove(mainScene.getObjectByName("doorModel9")!);
    mainScene.remove(mainScene.getObjectByName("doorModel12")!);
    mainScene.remove(mainScene.getObjectByName("doorModel3")!);
  }, []);

  const UpdateWindowRender = useCallback((mainScene: any) => {
    mainScene.remove(mainScene.getObjectByName("windowModel1")!);
    mainScene.remove(mainScene.getObjectByName("windowModel2")!);
    mainScene.remove(mainScene.getObjectByName("windowModel3")!);
    mainScene.remove(mainScene.getObjectByName("windowModel4")!);
    mainScene.remove(mainScene.getObjectByName("windowModel5")!);
    mainScene.remove(mainScene.getObjectByName("windowModel6")!);
    mainScene.remove(mainScene.getObjectByName("windowModel7")!);
    mainScene.remove(mainScene.getObjectByName("windowModel8")!);
    mainScene.remove(mainScene.getObjectByName("windowModel9")!);
    mainScene.remove(mainScene.getObjectByName("windowModel10")!);
    mainScene.remove(mainScene.getObjectByName("windowModel11")!);
    mainScene.remove(mainScene.getObjectByName("windowModel12")!);
    mainScene.remove(mainScene.getObjectByName("windowModel13")!);
    mainScene.remove(mainScene.getObjectByName("windowModel14")!);
    mainScene.remove(mainScene.getObjectByName("windowModel15")!);
  }, []);

  const updateYurt = useCallback(() => {
    if (mainScene === null) {
      return;
    }
    UpdateDoorRender(mainScene);
    UpdateWindowRender(mainScene);
    const topModel = mainScene.getObjectByName("top");
    if (topModel) {
      ChangeModelColor(topModel, yurt.topColor);
    }

    const baseModel = mainScene.getObjectByName("base");
    if (baseModel) {
      ChangeModelColor(baseModel, yurt.bottomColor);
    }

    if (isSceneAdded && LoadedModels === false) {
      LoadCommon(loader, mainScene, yurt.topColor, yurt.bottomColor);
      setLoadedModels(true);
    }
    LoadDoors(yurt.doors, loader, mainScene, yurt.size);
    LoadWindows(yurt.windows, loader, mainScene, yurt.size);
  }, [
    yurt,
    mainScene,
    loader,
    isSceneAdded,
    LoadedModels,
    UpdateDoorRender,
    UpdateWindowRender,
  ]);

  const updateStyles = () => {
    const ddButtons = document.querySelectorAll(
      '[id^="DDButton"]'
    ) as unknown as HTMLCollectionOf<HTMLElement>;
    const ddLowerSection = document.querySelectorAll(
      '[id^="lowerSection"]'
    ) as unknown as HTMLCollectionOf<HTMLElement>;
    const modelParent = document.querySelector(
      '[id^="modelContainer"]'
    ) as unknown as HTMLElement;

    if (isSceneAdded === false) {
      const modelScene = YurtScene();
      const renderer = modelScene.renderer;
      if (mainScene === null) {
        setMainScene(modelScene.scene);
        setLoader(modelScene.loader);
        setDomePrice(0);
        setComfortPrice(0);
        setWindowsPrice(0);
        setDoorsPrice(0);
      }
      modelParent.appendChild(renderer.domElement);
      setIsSceneAdded(true);
    }

    const buttonLen = ddButtons.length;
    const lowerLen = ddLowerSection.length;

    for (let i = 0; i < buttonLen; i++) {
      const button: HTMLElement = ddButtons[i];
      button.style.width = "80%";
      button.style.boxShadow =
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)";
      button.style.marginLeft = "0";
    }

    for (let i = 0; i < lowerLen; i++) {
      const lowerSection: HTMLElement = ddLowerSection[i];
      lowerSection.style.backgroundColor = "transparent";
      lowerSection.style.width = "75%";
      lowerSection.style.color = "black";
    }
  };

  useEffect(() => {
    updateStyles();
    updateYurt();
  });

  return (
    <div className={styles.container}>
      <div className={styles.totalContainer}>
        <div className={styles.total}>
          BASE: ${sizePrice} <br />
          STRUCTURE: ${structurePrice} <br />
          COMFORT: ${comfortPrice} <br />
          WINDOWS: ${windowsPrice} <br />
          DOORS: ${doorsPrice} <br />
          DOME: ${domePrice} <br />
          <br />
          TOTAL: $
          {sizePrice +
            structurePrice +
            comfortPrice +
            windowsPrice +
            doorsPrice +
            domePrice}
        </div>
      </div>
      <div className={styles.modelContainer} id="modelContainer">
        <div className={styles.model} id="modelContainer" />
      </div>
      <div className={styles.dropDownSection}>
        <div className={styles.header} >
          Get your customized yurt quote today and start your journey to luxury
          outdoor living.
        </div>

        {DropDown(
          "YURT SIZE",
          YurtSizeSection(yurt, setYurt, setSizePrice, mainScene, basePrices),
          true
        )}
        {DropDown(
          "STRUCTURE",
          YurtStructureSection(yurt.size, setStructurePrice)
        )}
        {DropDown("COMFORT", YurtComfortSection(yurt.size, setComfortPrice))}
        {DropDown("COLOR", YurtColorSection(yurt, setYurt))}
        {DropDown(
          "WINDOWS",
          YurtWindowSection(
            yurt,
            setYurt,
          )
        )}
        {DropDown(
          "DOORS",
          YurtDoorSection(
            yurt,
            setYurt,
          )
        )}
        {DropDown("DOME", YurtDomeSection(yurt.size, setDomePrice))}
        {DropDown(
          "PACKING & CRATING",
          YurtShippingSection(yurtShipping, setYurtShipping)
        )}
      </div>
    </div>
  );
};

export default QuotePage;
