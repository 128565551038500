import Slider from "react-slick";

import styles from "./homeCarouselStyles.module.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import outsideYurts41 from "../../../../imgs/gallerySection/outsidePhotos/outsideYurts41.jpeg";
import outsideYurts44 from "../../../../imgs/gallerySection/outsidePhotos/outsideYurts44.jpg";
import outsideYurts25 from "../../../../imgs/gallerySection/outsidePhotos/outsideYurts25.jpeg";
import outsideYurts28 from "../../../../imgs/gallerySection/outsidePhotos/outsideYurts28.jpeg";

const HomeCarousel = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        useTransform: false,
        adaptiveHeight: true,
        arrows: false,
        focusOnSelect: false,
        mobileFirst: true,
        respondTo: "min"

    };

    return (
        <div className={styles.container}>
            <Slider {...settings} className={styles.carousel}>
                <img className={styles.sliderImg} src={outsideYurts41} alt="slide1"></img>
                <img className={styles.sliderImg} src={outsideYurts44} alt="slide2"></img>
                <img className={styles.sliderImg} src={outsideYurts25} alt="slide3"></img>
                <img className={styles.sliderImg} src={outsideYurts28} alt="slide4"></img>
            </Slider>

            <div className={styles.callNowHeader}>
                When it comes to affordable <br />
                cabins, don't be square!! <br /> <br />
                <a className={styles.callNowButton} id="call" href="tel:317-377-9878">Call Now!</a>
            </div>
        </div>
    );
}

export default HomeCarousel;