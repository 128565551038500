import { createRoot } from "react-dom/client";
// import { store } from './app/store';
import App from "./App";
import "./index.css";
import Header from "./features/header/header";
import MobileHeader from "./features/header/mobileHeader";
import { BrowserRouter } from "react-router-dom";
import FooterSection from "./features/footerSection/footerSection";
import QuestionsForm from "./features/home/questionsForm/questionsForm";

const container = document.getElementById("root")!;
const root = createRoot(container);
const isMobile = window.matchMedia(
  "only screen and (max-width: 1171px)"
).matches;


root.render(
  // <Provider store={store}>
  <BrowserRouter>
    <div>
      {isMobile && <MobileHeader />}
      {!isMobile && <Header />}
      <App />
      <FooterSection />
      <QuestionsForm />
    </div>

  </BrowserRouter>
  /* </Provider> */
);
