import React, { useState, useEffect } from "react";
import styles from "./yurtComfortStyles.module.css";
import YOACheckBox from "../../../extensions/checkbox/YOACheckBox";

const YurtComfortSection = (yurtSize: number, setComfortPrice: React.Dispatch<React.SetStateAction<number>>) => {
    const [comfortSelection, editSelection] = useState<string[]>([])
    const [total, setTotal] = useState(0)

    const { yoaCheckbox: insulationTop, } = YOACheckBox("insulation top only", () => clickHandle("top"))
    const { yoaCheckbox: insulationTopSide, } = YOACheckBox("insulation top/side", () => clickHandle("topSide"))
    const { yoaCheckbox: fabric, } = YOACheckBox("side fabric up", () => clickHandle("fabric"))

    const updatePrice = () => {

        let tempTotal = 0;

        let top = false
        let topSide = false
        let fabric = false

        for (let i = 0; i < comfortSelection.length; i++) {
            if (comfortSelection[i] === "top") {
                top = true
            }
            if (comfortSelection[i] === "topSide") {
                topSide = true
            }
            if (comfortSelection[i] === "fabric") {
                fabric = true
            }
        }


        const addPrice = (topPrice: number, topSidePrice: number, fabricPrice: number) => {
            if (top) {
                tempTotal += topPrice
            }
            if (topSide) {
                tempTotal += topSidePrice
            }
            if (fabric) {
                tempTotal += fabricPrice
            }
        }

        switch (yurtSize) {
            case 16:
                addPrice(650, 1560, 400)
                break;
            case 20:
                addPrice(910, 1990, 550)
                break;
            case 24:
                addPrice(1285, 2550, 650)
                break;
            case 30:
                addPrice(1555, 3550, 799)
                break;
            default:
                break;
        }
        setTotal(tempTotal)
        setComfortPrice(tempTotal)
    }

    const clickHandle = (userSelection: string) => {
        const index = comfortSelection.indexOf(userSelection)
        if (index === -1) {
            editSelection([...comfortSelection, userSelection])
        } else {
            editSelection(comfortSelection.filter(item => item !== userSelection))
        }
        updatePrice()
    }

    useEffect(() => {
        updatePrice()
    })

    return (
        <div className={styles.structureContainer}>
            <div className={styles.structureGroup}>
                {insulationTop}<br /> <br />
                {insulationTopSide} <br /> <br />
                {fabric} <br /> <br />
            </div>
            <div className={styles.structureTotal}>comfort total: ${total}</div>
        </div>
    )
}

export default YurtComfortSection;