import styles from "./categorySelectionStyles.module.css";
import { YOARoutes } from "../../../Constants/Constants";
import outsideYurts5 from "../../../imgs/gallerySection/outsidePhotos/outsideYurts5.jpeg";
import insideYurts3 from "../../../imgs/gallerySection/insidePhotos/insideYurts3.jpeg"
import loftPhotos1 from "../../../imgs/gallerySection/loftPhotos/loftPhotos1.jpeg"
import specialProjects1 from "../../../imgs/gallerySection/specialProjectPhotos/specialProjects1.jpeg"
import options1 from "../../../imgs/gallerySection/optionsPhotos/options1.jpeg"
import underConstruction3 from "../../../imgs/gallerySection/underConstructionPhotos/underConstruction3.jpeg"
import Nav from "../../extensions/nav";

const CategorySelection = () => {
    const nav = Nav()
    return (
        <div>
            <div className={styles.container}>
                <div className={styles.title}>GALLERY.</div>

                <div className={styles.parallax}>
                    <div className={styles.imgGrid}>
                        <div onClick={() => nav(YOARoutes.imgCategory, { state: { category: "outside photos" } })} className={styles.imageCard}>
                            <img alt="outsidePhotos." className={styles.cardImg} src={outsideYurts5} />
                            <div className={styles.cardText}>
                                OUTSIDE PHOTOS.
                            </div>
                        </div>

                        <div onClick={() => nav(YOARoutes.imgCategory, { state: { category: "inside photos" } })} className={styles.imageCard}>
                            <img alt="insidePhotos" className={styles.cardImg} src={insideYurts3} />
                            <div className={styles.cardText}>
                                INSIDE PHOTOS.
                            </div>
                        </div>

                        <div onClick={() => nav(YOARoutes.imgCategory, { state: { category: "loft" } })} className={styles.imageCard}>
                            <img alt="loftPhotos" className={styles.cardImg} src={loftPhotos1} />
                            <div className={styles.cardText}>
                                LOFT.
                            </div>
                        </div>

                        <div onClick={() => nav(YOARoutes.imgCategory, { state: { category: "special projects" } })} className={styles.imageCard}>
                            <img alt="specialPhotos" className={styles.cardImg} src={specialProjects1} />
                            <div className={styles.cardText}>
                                SPECIAL PROJECT.
                            </div>
                        </div>

                        <div onClick={() => nav(YOARoutes.imgCategory, { state: { category: "options" } })} className={styles.imageCard}>
                            <img alt="optionsPhotos" className={styles.cardImg} src={options1} />
                            <div className={styles.cardText}>
                                OPTIONS.
                            </div>
                        </div>

                        <div onClick={() => nav(YOARoutes.imgCategory, { state: { category: "under construction" } })} className={styles.imageCard}>
                            <img alt="constructionPhotos" className={styles.cardImg} src={underConstruction3} />
                            <div className={styles.cardText}>
                                UNDER CONSTRUCTION.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}


export default CategorySelection