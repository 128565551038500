import { useState } from "react";
import styles from "./checkboxStyles.module.css";

const YOACheckBox = (text: String, clickHandle: any = undefined) => {
    const [checked, toggleChecked] = useState(false);

    const click = () => {
        if (clickHandle !== undefined) {
            toggleChecked(!checked)
            clickHandle()
        }
    }

    return ({
        yoaCheckbox:
            <div className={styles.container} onClick={click} >
                <div className={styles.checkBox} style={{ backgroundColor: checked ? "#a04c2e" : "transparent" }} onClick={click} />
                <div>{text}</div>
            </div>,
        toggleChecked
    })

}

export default YOACheckBox;