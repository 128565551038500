import { Scene } from "three";
const ScaleModel = (
  size: number,
  yurt: YurtType,
  setYurt: React.Dispatch<React.SetStateAction<YurtType>>,
  mainScene: Scene | null
) => {
  const dome = mainScene?.getObjectByName("dome");
  const top = mainScene?.getObjectByName("top");
  const base = mainScene?.getObjectByName("base");

  //doors
  const door3 = mainScene?.getObjectByName("doorModel3");
  const door6 = mainScene?.getObjectByName("doorModel6");
  const door9 = mainScene?.getObjectByName("doorModel9");
  const door12 = mainScene?.getObjectByName("doorModel12");

  if (top && base && dome) {
    switch (size) {
      case 16:
        dome.scale.x = 0.5333;
        dome.scale.z = 0.5333;
        dome.scale.y = 0.5333;
        dome.position.y = 0.5;

        top.scale.x = 0.5333;
        top.scale.y = 0.5333;
        top.scale.z = 0.5333;
        top.position.y = 0.5;

        base.scale.x = 0.5333;
        base.scale.z = 0.5333;

        //door scale

        if (door3) {
          door3.scale.z = 0.5333;
        }
        if (door6) {
          door6.scale.z = 0.5333;
        }
        if (door9) {
          door9.scale.z = 0.5333;
        }
        if (door12) {
          door12.scale.z = 0.5333;
        }

        setYurt({ ...yurt, size: 16 });

        //TODO: Windows
        break;
      case 20:
        dome.scale.x = 0.6667;
        dome.scale.z = 0.6667;
        dome.scale.y = 0.6667;
        dome.position.y = 0.3;

        top.scale.x = 0.6667;
        top.scale.y = 0.6667;
        top.scale.z = 0.6667;
        top.position.y = 0.3;

        base.scale.x = 0.6667;
        base.scale.z = 0.6667;

        //door scale

        if (door3) {
          door3.scale.z = 0.6667;
        }
        if (door6) {
          door6.scale.z = 0.6667;
        }
        if (door9) {
          door9.scale.z = 0.6667;
        }
        if (door12) {
          door12.scale.z = 0.6667;
        }

        //TODO: Windows

        break;

      case 24:
        dome.scale.x = 0.8;
        dome.scale.z = 0.8;
        dome.scale.y = 0.8;
        dome.position.y = 0.2;

        top.scale.x = 0.8;
        top.scale.y = 0.8;
        top.scale.z = 0.8;
        top.position.y = 0.2;

        base.scale.x = 0.8;
        base.scale.z = 0.8;

        //door scale

        if (door3) {
          door3.scale.z = 0.8;
        }
        if (door6) {
          door6.scale.z = 0.8;
        }
        if (door9) {
          door9.scale.z = 0.8;
        }
        if (door12) {
          door12.scale.z = 0.8;
        }

        //TODO: Windows

        break;
      case 30:
        dome.scale.x = 1;
        dome.scale.z = 1;
        dome.scale.y = 1;
        dome.position.y = 0;

        top.scale.x = 1;
        top.scale.y = 1;
        top.scale.z = 1;
        top.position.y = 0;

        base.scale.x = 1;
        base.scale.z = 1;

        //door scale

        if (door3) {
          door3.scale.z = 1;
        }
        if (door6) {
          door6.scale.z = 1;
        }
        if (door9) {
          door9.scale.z = 1;
        }
        if (door12) {
          door12.scale.z = 1;
        }

        //TODO: Windows

        break;

      default:
        break;
    }
  }
};

export default ScaleModel;
